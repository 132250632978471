import { useFetch } from "hooks/useFetch";
import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Spinner,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Card,
  Row,
  Col,
  Button,
  CardHeader
} from "reactstrap";
import img from "../../assets/img/theme/cover-photo.jpg";
export default function ShowDetailsSpectator() {
  const { showId } = useParams();
  const { data, isPending } = useFetch(process.env.REACT_APP_NODE_SERVER + "/api/show/getById/" + showId);
  const { data: eventCollection, error:eventError,isPending: eventCollectionPending } = useFetch(process.env.REACT_APP_NODE_SERVER+"/api/event-collection/getAllEvent/"+showId);
  if (isPending && eventCollectionPending) {
    return (
      <div className="text-center container pt-8">
        <Spinner
          color="primary"
          style={{ height: "3rem", width: "3rem" }}
        ></Spinner>
      </div>
    );
  } else {
    return (
      <div className="container pt-8">
        {/* Event Details for the show */}
        {data && <div>
            <Row>
            <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h2 className="mb-0">{data.show.show_name}</h2>
                  </Col>
                  <Col className="text-right" xs="4">
                    {/* <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Settings
                    </Button> */}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <p style={{'fontWeight':'bold'}}><strong><span className="fw-bold"><i className="ri-map-pin-line"></i>{' '}{data.show.show_venue}</span></strong></p>
                <p> <i className="ri-calendar-check-fill"></i>{' '}
                {new Date(data.show.show_start_date).toLocaleDateString('en-US',{ day: 'numeric', month: 'short', year: 'numeric'})} to {new Date(data.show.show_end_date).toLocaleDateString('en-US',{ day: 'numeric', month: 'short', year: 'numeric'})}</p>
                <p>{data.show.show_desc.split(' ').slice(0, 20).join(' ')}...</p>
              </CardBody>
            </Card>
          </Col>
            </Row>
        </div>}
        {/* Events for the show */}
        <div>
          <h2 className="mt-4">Event(s)</h2>
          <div className="row">
          {eventError && <p className="text-danger">Sorry! no events are available!</p> }
            {eventCollection &&
              eventCollection.eventCollection.map((each) => (
                <div className="col-sm-4" key={each.event_collection_id}>
                  <Card className="mt-2" style={{ width: "18rem" }}>
                    <img
                      alt="Sample"
                      src={img}
                      style={{ position: "relative" }}
                    />
                    <CardBody>
                      <CardTitle tag="h3">{each.event_name}</CardTitle>
                      <CardSubtitle className="mb-2" tag="h5">
                        ( {each.subevent_name} )
                      </CardSubtitle>
                      <CardText>
                        <div>
                        <small className="mr-2"><i className="ri-calendar-event-line"></i> {' '}{new Date(each.event_date).toLocaleDateString('en-US',{ day: 'numeric', month: 'short', year: 'numeric'})}</small>{' '}{' '}
                          <small><i className="ri-time-line"></i> {' '}{new Date(`1970-01-01T${each.event_time}Z`).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true, timeZone: 'UTC' })}</small>
                        </div>
                        {each.categories.map((each) => (
                          <small key={each.category_id}>
                            • {each.category_name} &nbsp;
                          </small>
                        ))}
                      </CardText>
                      <Link to={`/spectator/enrollment-details/${each.show_id}/${each.event_id}/${each.subevent_id}/${each.event_collection_id}`}><Button color="primary">
                        View Entires
                      </Button>
                      </Link>
                    </CardBody>
                  </Card>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
