import React from 'react'
import {
  Button,
  Card,
  CardBody,
  Spinner,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";
import img from "../../assets/img/theme/cover-photo.jpg"
import { useFetch } from 'hooks/useFetch';
import { Link } from 'react-router-dom';
export default function CaptainIndex() {
  const {isPending,data} = useFetch(process.env.REACT_APP_NODE_SERVER+"/api/show/getAll")
  if(isPending){
    return(
    <div className='container text-center pt-9'>
    <Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner>
    </div>
    )
  }
  else{
  return (
    <div className='container pt-7'>
        <h2 className="mt-5">Available Show(s)</h2>
        <div className="row">
        {data && data.shows.map((each)=>( 
        <div className="col-sm-4" key={each.show_id}>
        <Card className="mt-2" style={{ width: '18rem' }}>
        <img
          alt="Sample"
          src={img}
          style={{'position':'relative'}}
        />
        <CardBody>
          <CardTitle tag="h3">
            {each.show_name}
          </CardTitle>
          <CardSubtitle
            className="mb-2"
            tag="h5"
          >
            <i className="ri-map-pin-line"></i>{' '} {each.show_venue}
          </CardSubtitle>
          <CardSubtitle
            className="mb-2"
            tag="h5"
          >
            <i className="ri-calendar-check-fill"></i>{' '}
           {new Date(each.show_start_date).toLocaleDateString('en-US',{ day: 'numeric', month: 'short', year: 'numeric'})} to {new Date(each.show_end_date).toLocaleDateString('en-US',{ day: 'numeric', month: 'short', year: 'numeric'})}
          </CardSubtitle>
          <CardText> 
          </CardText>
          <Link to={`/captain/show-detail/${each.show_id}`}>
            <Button color="primary" className="w-100">
              View Details
            </Button>
          </Link>
        </CardBody>
      </Card>
      </div>))}
      </div>
      <div className='mt-4'><i>Note: To Add/View Entires Click on View Details</i></div>
      
    </div>
  )
}
}
