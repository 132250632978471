import { useFetch } from 'hooks/useFetch';
// reactstrap components
import {
    Card,
    CardHeader,
    Table,
    Row,
    Spinner,
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
  
export default function TopHorse() {
    const {data:topHorse,isPending:teamIsPending} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/results/getTotalHorsePoints');
  return (
    <div className="container pt-8">
         {topHorse && topHorse.totalHorsePointsWithNamesAndTeams.length===0 &&<p className='text-danger'>The results are not published yet!</p>}
        {topHorse && topHorse.totalHorsePointsWithNamesAndTeams.length>0 &&<Row>
          <div className="col">
            {<Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Top Horse</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th/>
                    <th scope="col">Horse Name</th>
                    <th scope="col">Team Name</th>
                    <th scope="col">Horse Point</th>
                  </tr>
                </thead>
                <tbody>
                  {topHorse && topHorse.totalHorsePointsWithNamesAndTeams.map((horse,index)=>(<tr key={index}>
                    <th>{index+1}</th>
                    <th scope="row">
                        {horse.horse_name}
                    </th>
                    <th>{horse.team_name}</th>
                    <th>{horse.total_horse_points}</th>
                  </tr>))}
                  {teamIsPending  && <tr className='d-flex justify-content-center align-items-center p-4'><td><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></td></tr>}
                </tbody>
              </Table>
              
            </Card>}
          </div>
        </Row>}
    </div>
  )
}
