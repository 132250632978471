import React, { useEffect, useState } from "react";
import { Button, Spinner, FormGroup, Input, InputGroup } from "reactstrap";
import { MultiSelect } from 'primereact/multiselect';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { usePost } from "hooks/usePost";
import { useFetch } from "hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export default function AddSubDiscipline() {
  const {disciplineId} = useParams();
  const [rows, setRows] = useState([{ subevent_name: '', subevent_desc: '',event_id:disciplineId}]);
  const [isLoader,setIsLoder] = useState(false);
  const navigate = useNavigate();
  const { postData, resData, postError, isPending: postIsPending } = usePost();

  const handleCreateEventsCollection = async (e) => {
    e.preventDefault();
    //console.log(rows);
    postData(process.env.REACT_APP_NODE_SERVER + '/api/subevent/add-subevents', rows, "POST");
  };

  const handleAddRow = () => {
    setRows([...rows, { subevent_name: '', subevent_desc: '',event_id:disciplineId}]);
  };
  const handleRowChange = (e, currentRow, index, field) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      if (field === 'subevent_name') {
        updatedRows[index] = {
          ...currentRow,
          subevent_name: (e.target.value),
        };
      }
      if (field === 'subevent_desc') {
        updatedRows[index] = {
          ...currentRow,
          subevent_desc: (e.target.value),
        };
      }
     
      //console.log(updatedRows);
      return updatedRows;
    });
  };
  const handleDeleteRow = (index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows.splice(index, 1);
      return updatedRows;
    });
  };
  const handleNavigate = () =>{
    navigate(`/admin/discipline-details/${disciplineId}`);
 }
  useEffect(()=>{
    if(resData && resData.status===200){
        NotificationManager.success("Sub Discipline(s) Added Successfully!", 'Success', 3000);
        setIsLoder(true);
        setTimeout(() => {
            handleNavigate();
          }, 3000);
    }
    if(postError){
        NotificationManager.error(postError, 'Error', 5500);
        return;
    }
  },[resData,postError])
  return (
    <div className="container pt-8">
        <NotificationContainer/>
        {isLoader && 
            <div className="text-center"> 
                <Spinner color="primary"style={{ height: '3rem', width: '3rem'}}></Spinner>
        </div>}
        {!isLoader && <>
      <div className="text-center text-muted mb-4">
        <small style={{'textTransform':"capitalize"}} className="text-uppercase"><b>ADD SUBDISCIPLINE(S)</b></small>
      </div>
      <form onSubmit={handleCreateEventsCollection}>
        {rows.map((each, index) => (
          <div  key={index} className="card  p-4 mb-4">
          <div className="row" key={index}>
            <div className="col-sm-12 pb-3 text-right">
              <svg onClick={()=>handleDeleteRow(index)} style={{'cursor':'pointer'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" className="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
              </svg>
            </div>
            <div className="col-sm-6">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <Input
                    // onChange={(e) => {setRows((prevRows) => updateRow(prevRows, index, 'event_id', e.target.value))}}
                    onChange={(e)=>handleRowChange(e, each, index,'subevent_name')}
                    type="text"
                    name={`selectevent-${index}`}
                    id={`selectevent-${index}`}
                    value={(each.subevent_name)}
                    placeholder="Sub Discipline Name"
                  />
                </InputGroup>
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                <Input
                    // onChange={(e) => {setRows((prevRows) => updateRow(prevRows, index, 'event_id', e.target.value))}}
                    onChange={(e)=>handleRowChange(e, each, index,'subevent_desc')}
                    type="text"
                    name={`selectevent-${index}`}
                    id={`selectevent-${index}`}
                    value={(each.subevent_desc)}
                    placeholder="Sub Discipline Description"
                  />
                </InputGroup>
              </FormGroup>
            </div>
          </div>
          </div>
        ))}
            <div className="text-center">
              {!postIsPending && (
                <Button color="primary" type="button" onClick={handleAddRow}>
                  <i className="ni ni-fat-add"></i>Add
                </Button>
              )}
              {!postIsPending && (
                <Button color="primary" type="submit">
                  Submit
                </Button>
              )}
              {postIsPending && (
                <Button color="primary" disabled>
                  <Spinner size="sm" />
                  {' '}Submitting...
                </Button>
              )}
            </div>
      </form>
      </>}
      <p className="pt-5"><i>Note if a Discipline does not have a name add N/A as Sub Discipline Name.</i></p>
    </div>
  );
}
// const updateRow = (rows, index, field, value) => {
//   return rows.map((row, i) => (i === index ? { ...row, [field]: value } : row));
// };
