import { useFetch } from 'hooks/useFetch';
import React, { useEffect, useState } from 'react'
// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Row,
    Spinner,
    UncontrolledTooltip,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
  import {NotificationContainer, NotificationManager} from 'react-notifications';
  import { Link } from 'react-router-dom';
import { useAuthContext } from 'hooks/useAuthContext';
import { usePost } from 'hooks/usePost';
export default function GetAllHorses() { 
    const {user} = useAuthContext();
    const [teamId,setTeamId]=useState('')
    const {data:team,isPending:teamIsPending} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/team/getByCaptainId/'+user.userId);
    const {data, isPending, error}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/horse/getAll/'+Number(teamId));
    const [modal, setModal] = useState(false);
    const [horseId,sethorseId] = useState(null);
    const {postData,resData,postError,isPending:deletePending} = usePost();
    useEffect(() => {
        if(team){
            setTeamId(team.team.team_id)
        }
    }, [team])
    useEffect(()=>{
      if(error){
        NotificationManager.error(error, 'Error', 3000);
      }
    },[error])

    const toggle = (horseId) => {
      if(!modal){
        sethorseId(horseId);
      }
      setModal(!modal);
    };

    const handleDelete = () =>{
      postData(process.env.REACT_APP_NODE_SERVER+"/api/horse/archive-horse/"+horseId,{},"PUT")
    }

    useEffect(()=>{
      if(resData && resData.status===200){
        NotificationManager.success("Horse deleted successfully!", 'Success', 3000);
        setModal(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      if(postError){
        NotificationManager.error(postError, 'Error', 3000);
      }
    },[resData,postError])
  return (
    <div className="container pt-8">
      <NotificationContainer />
      {horseId && <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete Horse</ModalHeader>
        <ModalBody>
              <div>
                Are you sure you want to delete the horse?
              </div>
        </ModalBody>
        <ModalFooter>
          {!deletePending && <Button color="danger" onClick={handleDelete}>Delete</Button>}
          {deletePending &&<Button color="danger" disabled>Deleting...</Button>}
          <Button onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>}
        <Row>
          <div className="col">
            {<Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">All horses</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th/>
                    <th scope="col">Name</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Birth Year</th>
                    <th scope="col">Color</th>
                    <th scope="col">Registration No.</th>
                    <th scope="col">Club</th>
                    <th scope="col">Id Proof</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.horses.map((horse)=>(<tr key={horse.horse_id}>
                    <th><a
                          className="avatar avatar-sm"
                          href={process.env.REACT_APP_NODE_SERVER+"/"+horse.horse_photo}
                          id={`tooltip${horse.horse_id}`}
                          target="_blank" rel="noreferrer" download
                        >
                          <img
                            alt="..."
                            className="rounded-circle"
                            style={{height:"50px", width:"50px",objectFit:'cover'}}
                            src={process.env.REACT_APP_NODE_SERVER+"/"+horse.horse_photo}
                          />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target={`tooltip${horse.horse_id}`}
                        >
                          {horse.name}
                        </UncontrolledTooltip></th>
                    <th scope="row">
                     <Link to={`/captain/horse-details/${horse.horse_id}`}>{horse.name}</Link>
                    </th>
                    <td>{horse.gender}</td>
                    <td>{horse.birth_year}</td>
                    <td>
                      {horse.color}
                    </td>
                    <td>{horse.registration_no}</td>
                    <td>{horse.club_name}</td>
                    <td><a href={process.env.REACT_APP_NODE_SERVER+"/"+horse.id_proof} target="_blank" rel="noreferrer" download> <img
                            alt="..."
                            style={{height:"50px", width:"50px",objectFit:'contain'}}
                            src={process.env.REACT_APP_NODE_SERVER+"/"+horse.id_proof}
                          /></a></td>
                          <td><i style={{fontSize:"20px",cursor:"pointer"}} onClick={()=>toggle(horse.horse_id)} className="ri-delete-bin-6-line text-danger"></i></td>
                  </tr>))}
                  {teamIsPending && isPending && <tr className='d-flex justify-content-center align-items-center p-4'><td><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></td></tr>}
                </tbody>
              </Table>
              <CardFooter className="py-4">
              {data && data.horses.length===0 && <tr className='m-5'><p>No Horse Are Available! <Link to="/captain/add-horse">Add Horses</Link></p></tr>}
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>}
          </div>
        </Row>
    </div>
  )
}
