import { useAuthContext } from 'hooks/useAuthContext';
import { useFetch } from 'hooks/useFetch';
import React, {  useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Spinner, FormGroup, Input, InputGroup, FormText } from "reactstrap";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
export default function AddHorse() {
    const {user} = useAuthContext();
    const [rows, setRows] = useState([
        {name: "",gender: "",birth_year: "",club_name: "",team_id: "",color: "",registration_no: "",id_proof: "",horse_photo: "",created_at: "",updated_at: ""}
    ])
    const {data:team} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/team/getByCaptainId/'+user.userId);
    const navigate = useNavigate();
    const [isLoader,setIsLoder] = useState(false);
    const [addHorsePending,setAddHorsePending] = useState(false);
    const handleRowChange = (e, currentRow, index, field) =>{
        const updatedRows = [...rows];

    // Check if the field is 'id_proof' and handle file upload
    if (field === 'id_proof') {
        const file = e.target.files[0];
        updatedRows[index] = { ...currentRow, [field]: file };
    } 
    else if (field === 'horse_photo') {
        const file = e.target.files[0];
        updatedRows[index] = { ...currentRow, [field]: file };
    } 
    
    else {
        updatedRows[index] = { ...currentRow, [field]: e.target.value };
    }

    setRows(updatedRows);   
    }
    const handleDeleteRow = (index) =>{
        if (rows.length > 1) {
            const updatedRows = [...rows];
            updatedRows.splice(index, 1);
            setRows(updatedRows);
          }
    }
    const handleAddRow = () =>{
        setRows([...rows,{name: "",gender: "",birth_year: "",club_name: "",team_id: "",color: "",registration_no: "",id_proof: "",horse_photo: "",created_at: "",updated_at: ""}])
    }
    const handleFormSubmit = async(e) =>{
        e.preventDefault()
        setAddHorsePending(true);
        const formData = new FormData();

        const payload = rows.map((row) => {
            // Append non-file fields to the FormData
            formData.append('name', row.name);
            formData.append('gender', row.gender);
            formData.append('id_proof', row.id_proof);
            formData.append('horse_photo', row.horse_photo);
        
            // Return the updated row object
            return {
              ...row,
              club_name: team.team.team_name,
              team_id: team.team.team_id,
              created_at: new Date(),
              updated_at: new Date(),
            };
          });
        
          //console.log(payload);
          formData.append('data', JSON.stringify(payload));

  // Make the API call with the FormData
  try {
    const response = await fetch(
      process.env.REACT_APP_NODE_SERVER + '/api/horse/add-horses',
      {
        method: 'POST',
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error('All the fields are required!');
    }

    const responseData = await response.json();
    //console.log(responseData);
    // Handle success response
    if(responseData && responseData.status===200){
        NotificationManager.success("Horse(s) Added Successfully!", 'Success', 3000);
        setAddHorsePending(false);
        setIsLoder(true);
        setTimeout(() => {
            handleNavigate();
          }, 3000);
    }
  } catch (error) {
    console.error('Error:', error);
    setAddHorsePending(false);
    NotificationManager.error(error.message, 'Error', 3000);
  }

    }
    const handleNavigate = () =>{
        navigate(`/captain/all-horses/`);
     }
    // useEffect(()=>{
    //     if(resData && resData.status===200){
    //         NotificationManager.success("Rider(s) Added Successfully!", 'Success', 3000);
    //         setIsLoder(true);
    //         setTimeout(() => {
    //             handleNavigate();
    //           }, 3000);
    //     }
    //     if(postError){
    //         NotificationManager.error(postError, 'Error', 3000);
    //         return;
    //     }
        
    // },[resData,postError])
  return (
    <div className="container pt-8">
        {isLoader && 
            <div className="text-center"> 
                <Spinner color="primary"style={{ height: '3rem', width: '3rem'}}></Spinner>
        </div>}
        <NotificationContainer/>
        {!isLoader && <div>
        <h3>Add Horse(s)</h3>
            <form onSubmit={handleFormSubmit} encType="multipart/form-data">
                {rows.map((each,index)=>(<div className="card p-4 mb-4" key={index}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4>Horse #{index+1}</h4>
                <div className='p-2 text-right'>
                        <svg onClick={()=>handleDeleteRow(index)} style={{'cursor':'pointer'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" className="bi bi-trash" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                        </svg>
                </div>
                </div>
                    <div className="row">
                     <div className="col-sm-4">
                        <FormGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                                <Input
                                    placeholder="Horse Name"
                                    type="text"
                                    value={each.name}
                                    onChange={(e)=>handleRowChange(e, each, index, 'name')}
                                />
                            </InputGroup>
                        </FormGroup>
                     </div>
                     <div className="col-sm-4">
                        <FormGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                                <Input
                                    placeholder="Gender"
                                    type="select"
                                    value={each.gender}
                                    onChange={(e)=>handleRowChange(e, each, index, 'gender')}
                                >
                                    <option value=''>Select Gender</option>
                                    <option value='Gelding'>Gelding</option>
                                    <option value='Mare'>Mare</option>
                                    <option value='Stallion'>Stallion</option>
                                </Input>
                            </InputGroup>
                        </FormGroup>
                     </div>
                     <div className="col-sm-4">
                        <FormGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                                <Input
                                    placeholder="Birth Year"
                                    type="text"
                                    value={each.birth_year}
                                    onChange={(e)=>handleRowChange(e, each, index, 'birth_year')}
                                />
                            </InputGroup>
                        </FormGroup>
                     </div>
                     <div className="col-sm-4">
                        <FormGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                                <Input
                                    placeholder="Horse Color"
                                    type="text"
                                    value={each.color}
                                    onChange={(e)=>handleRowChange(e, each, index, 'color')}
                                />
                            </InputGroup>
                        </FormGroup>
                     </div>
                     <div className="col-sm-4">
                        <FormGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                                <Input
                                    placeholder="Registration Number"
                                    type="text"
                                    value={each.registration_no}
                                    onChange={(e)=>handleRowChange(e, each, index, 'registration_no')}
                                />
                            </InputGroup>
                        </FormGroup>
                     </div>
                     <div className="col-sm-4">
                        <FormGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                            <Input
                                placeholder="Id Proof"
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleRowChange(e, each, index, 'id_proof')}
                            />
                            </InputGroup>
                            <FormText>
                                    <em>Photo of ID Proof</em>
                            </FormText>
                        </FormGroup>
                     </div>
                     <div className="col-sm-4">
                        <FormGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                            <Input
                                placeholder="Horse Photo"
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleRowChange(e, each, index, 'horse_photo')}
                            />
                            </InputGroup>
                            <FormText>
                                    <em>Horse Photo</em>
                            </FormText>
                        </FormGroup>
                     </div>
                     </div>
                </div>))}
                <Button onClick={handleAddRow} color="primary">
                    <svg xmlns="http://www.w3.org/2000/svg" style={{fontWeight:'700'}}width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                    </svg>{' '} 
                    Add Horse
                </Button>
                {!addHorsePending && <Button color="primary">Submit</Button>}
                {addHorsePending && <Button color="primary" disabled>Submitting...</Button>}
            </form>
            </div>}
    </div>
  )
}
