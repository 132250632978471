import { useFetch } from 'hooks/useFetch';
import React, { useEffect } from 'react'
// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Row,
    Spinner,
    UncontrolledTooltip
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
  import {NotificationContainer, NotificationManager} from 'react-notifications';
  import { Link } from 'react-router-dom';
export default function ViewAllTeam() {
    const {data, isPending, error}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/team/getAll');

    useEffect(()=>{
      if(error){
        NotificationManager.error(error, 'Error', 3000);
      }
    },[error])

  return (
    <div className="container pt-8">
      <NotificationContainer />
        <Row>
          <div className="col">
            {<Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">All Teams</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th/>
                    <th scope="col">Team Name</th>
                    <th scope="col">Captain</th>
                    <th scope="col">Team Description</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.teams.map((team)=>(<tr key={team.team_id}>
                    <th>
                    <a
                          className="avatar avatar-sm"
                          href={process.env.REACT_APP_NODE_SERVER+"/uploads/"+team.team_logo}
                          id={`tooltip${team.team_id}`}
                          target="_blank" rel="noreferrer" download
                        >
                          <img
                            alt="..."
                            className="rounded-circle"
                            style={{height:"40px", width:"40px",objectFit:'cover'}}
                            src={process.env.REACT_APP_NODE_SERVER+"/uploads/"+team.team_logo}
                          />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target={`tooltip${team.team_id}`}
                        >
                          {team.team_name}
                        </UncontrolledTooltip>
                    </th>
                    <th scope="row">
                     <Link to={`/admin/team-details/${team.team_id}`}>{team.team_name}</Link>
                    </th>
                    <td><Link to="/admin/view-captains">{team.captain_names.slice(0,25)}...</Link></td>
                    <td>
                      {team.team_desc}
                    </td>
                  </tr>))}
                  {isPending && <tr className='d-flex justify-content-center align-items-center p-4'><td><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></td></tr>}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>}
          </div>
        </Row>
    </div>
  )
}
