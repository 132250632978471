import { useAuthContext } from "hooks/useAuthContext";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Navbar,
  Nav,
  Container,
} from "reactstrap";

const SpectatorNavbar = (props) => {

  return (
    <>
      <Navbar className="navbar-top bg-gradient-info navbar-dark py-4" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/spectator/index"
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <Link to='/auth/login'><div className="text-white"><i className="ri-login-box-line text-white" style={{fontSize:"20px"}}></i>{' '} Login</div></Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default SpectatorNavbar;
