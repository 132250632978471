import React from 'react'
import { Button } from 'reactstrap';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
export default function ShowJumpingFaultAndOut({data}) {
    const generatePDF = () => {
        const doc = new jsPDF({
            orientation: 'landscape', // Set orientation to landscape
          });
          doc.text(`SCORE SHEET: ${data.enrollmentsForCategory[0].show_name.toUpperCase()}`, 20, 10);
    
        // Set the Y coordinate for the third line
        doc.setDrawColor(0); // reset draw color (black)
        doc.setFillColor(255); // set fill color to white
        doc.setLineWidth(0.5); 
        doc.setLineWidth(0.3);// set line width
        // doc.line(20, 60, 190, 60); // Horizontal line as separator
        doc.setFontSize(10);
        // Add the third line of text below the second line
        doc.text('Arena:', 20, 20);
        doc.text('Date:', 80, 20);
        doc.text('Event: Show Jumping Fault and Out', 140, 20);
        doc.text(`Category: ${data.enrollmentsForCategory[0].category_name}`, 260, 20);

         // Add the forth line of text below the second line
         doc.text('Table:', 20, 30);
         doc.text('Speed:', 80, 30);
         doc.text('Height:', 140, 30);
         doc.text('Length:', 200, 30);
         doc.text('Obstacles:', 260, 30);

         // Add the Fith line of text below the second line
         doc.text('Time allowed:', 20, 40);
         doc.text('Time limit:', 80, 40);
         doc.text('Efforts:', 140, 40);
         doc.text('Start Time:', 200, 40);
         doc.text('Course Walk:', 260, 40);
          
        const tableData = {
            html: '#my-table',
            theme: 'grid', 
            startY: 50,// Specify the theme here
            styles: {
              // You can also customize other styles here
              fontSize: 8,
              fontStyle: 'normal',
              textColor: [0, 0, 0],
              cellPadding: 2,
              lineColor: [0, 0, 0],
            },
            headerStyles: {
              fillColor: [211, 211, 211], // Light grey color
            },
          };
        
          doc.autoTable(tableData);
          doc.text('Name of Judge 1:', 20, doc.autoTable.previous.finalY + 10);
          doc.text('Signature of Judge 1:', 80, doc.autoTable.previous.finalY + 10);
          doc.text('Name of Judge 2:', 20, doc.autoTable.previous.finalY + 20);
          doc.text('Signature of Judge 2:', 80, doc.autoTable.previous.finalY + 20);
          doc.line(20, doc.autoTable.previous.finalY + 30, 60, doc.autoTable.previous.finalY + 30);
          doc.text('President Ground Jury', 20, doc.autoTable.previous.finalY + 40);
        // Save the PDF
        doc.save('scoring_sheets.pdf');
      };
  return (
    <>
      <div className='mb-4'><Button color="success" onClick={generatePDF}>Download PDF</Button></div>
      <table id="my-table" className="table table-bordered">
  <thead>
    <tr>
      <th scope="col">S. NO</th>
      <th scope="col">NAME OF RIDER</th>
      <th scope="col">HORSE NAME</th>    
      <th scope="col" colSpan="16">FENCE NO.
      </th>
      <th scope="col">JUMPING PEN.</th>
      <th scope="col">TIME</th>
      <th scope="col">TIME PEN.</th>
      <th scope="col">TOTAL PEN.</th>

    </tr>
  </thead>
  <tbody>
    <tr>
      <td colSpan='3'></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    {data && data.enrollmentsForCategory.map((each, index) => (
      <tr key={index}>
        <th scope="row">{index + 1}</th>
        <td>{each.rider_name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</td>
        <td>{each.name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    ))}
    
  </tbody>
</table>
    </>
  )
}
