import { useFetch } from 'hooks/useFetch';

// reactstrap components
import {
    Card,
    CardHeader,
    Table,
    Row,
    Spinner,
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';

export default function TopTeam() {
    const {data:team,isPending:teamIsPending} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/results/getTotalTeamPoints');
  return (
    <div className="container pt-8">
         {team && team.totalTeamPointsWithNames.length===0 &&<p className='text-danger'>The results are not published yet!</p>}
        {team && team.totalTeamPointsWithNames.length>0 &&<Row>
          <div className="col">
            {<Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Top Team</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th/>
                    <th scope="col">Team Name</th>
                    <th scope="col">Team Point</th>
                  </tr>
                </thead>
                <tbody>
                  {team && team.totalTeamPointsWithNames.map((team,index)=>(<tr key={index}>
                    <th>{index+1}</th>
                    <th scope="row">
                        {team.team_name}
                    </th>
                    <th>{team.total_team_points}</th>
                  </tr>))}
                  {teamIsPending  && <tr className='d-flex justify-content-center align-items-center p-4'><td><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></td></tr>}
                </tbody>
              </Table>
            </Card>}
          </div>
        </Row>}
    </div>
  )
}
