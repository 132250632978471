// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import AvailableShows from "./AvailableShows";
// core components

const SpectatorIndex = () => {
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundImage:
            "url(" + require("../../assets/img/theme/cover-photo-2.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-7" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              <h1 className="display-2 text-white">Welcome</h1>
              <p className="text-white mt-0 mb-5">
              Discover equestrian excellence at our captivating shows, uniting riders and horses in a celebration of skill and grace. Immerse yourself in the world of equine beauty, athleticism, and pursuit of excellence.
              </p>
              <Button
                color="info"
                href="/spectator/index/#availables-shows"
              >
                View Shows
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
     <AvailableShows/>
    </>
  );
};

export default SpectatorIndex;
