import 'jspdf-autotable';
import { useFetch } from 'hooks/useFetch';
import { useParams } from 'react-router-dom';
export default function ResultSpectator() {
    const { eventId, subeventId, showId, categoryId } = useParams();
    const { data } = useFetch(
      `${process.env.REACT_APP_NODE_SERVER}/api/enrollment/getEnrollmentsForEventsByCategory/${eventId}/${subeventId}/${showId}/${categoryId}`
    );
    const {data:results} = useFetch(process.env.REACT_APP_NODE_SERVER+`/api/results/getResultsByParamsExcludingZero?category_id=${data ?data.enrollmentsForCategory[0].category_id:''}&show_id=${data?data.enrollmentsForCategory[0].show_id:''}&event_id=${data ? data.enrollmentsForCategory[0].event_id:""}&subevent_id=${data ?data.enrollmentsForCategory[0].subevent_id:''}`)
  

  
  return (
    <div className='container pt-7'>
   { data && <h2 className='mt-3'>Start Order For - {data.enrollmentsForCategory[0].event_name} ({data.enrollmentsForCategory[0].subevent_name})</h2>}
   { data && <h3 className='mt-3 mb-4'>Category - {data.enrollmentsForCategory[0].category_name}</h3>}
   {results && results.results.length===0 && <p className='text-danger'>Results are not available yet!</p>}
    {/* results */}
    {results && results.results.length>0 && <table id="my-table" className="table table-bordered">
        <thead>
            <tr>
            <th scope="col">S. NO</th>
            <th scope="col">RIDER</th>
            <th scope="col">HORSE NAME</th>
            <th scope="col">CLUB</th>
            {data.enrollmentsForCategory[0].event_id === 5 && <th scope="col">{data.enrollmentsForCategory[0].subevent_id===28?"TOTAL":"JUMPING PENALTY"}</th>} 
            {data.enrollmentsForCategory[0].event_id === 5 && <th scope="col">TIME</th>}
             {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id !==28 &&<th scope="col">TIME PENALTY</th>}
             {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id !==28 &&<th scope="col">TOTAL PENALTY</th>}
            <th scope="col">POSITION</th>
            </tr>
        </thead>
        <tbody>
            {results && results.results.map((each, index) => (
            <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{each.rider_name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</td>
                <td>{each.name} {each.hc === 1 ? <span className='text-danger'>(HC)</span> : ""}</td>
                <td>{each.team_name}</td>
                {data.enrollmentsForCategory[0].event_id === 5 &&<td >{each.jumping_penalty}</td>}
                 {data.enrollmentsForCategory[0].event_id === 5 &&<td >{each.time}</td>}
                 {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id!==28 &&<td >{each.time_penalty}</td>}
                 {data.enrollmentsForCategory[0].event_id === 5 && data.enrollmentsForCategory[0].subevent_id!==28 &&<td >{each.total_penalty}</td>}
                <td className='highlight-position text-white'>{each.position}</td>
            </tr>
            ))}
            
        </tbody>
    </table>}
    <style>
                {`
                    .highlight-position {
                        background-color:#32de84; /* You can set any background color you prefer */
                        font-weight: bold; /* You can adjust the font weight for emphasis */
                    }
                `}
            </style>
    </div>
  )
}
