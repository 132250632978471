// reactstrap components
import { usePost } from "hooks/usePost";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useAuthContext } from "hooks/useAuthContext";

const ShowOfficeLogin = () => {
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const {postData,resData,postError,isPending} = usePost();
  const{dispatch}=useAuthContext();
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    const payload={
       email,
      password
    }
    postData(process.env.REACT_APP_NODE_SERVER+'/api/show-office/login',payload,"POST")
  }
  useEffect(()=>{
    if(resData && resData.status===200){
      if(resData.token){
        localStorage.setItem('token',JSON.stringify(resData));
        dispatch({type:'LOGIN',payload:resData});
        navigate('/showoffice/showoffice-index');
      }
    }
  },[resData])

  useEffect(()=>{
    if(postError !== null){
      NotificationManager.error(postError, 'Error', 3000);
    }
  },[postError])
  return (
    <>
      <NotificationContainer/>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
                <h3>Show Office Login</h3>
              <small>Sign in with credentials</small>
            </div>
            <form onSubmit={handleLogin}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={(e)=>setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
              {!isPending &&<Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>}
                {isPending && <Button className="my-4" color="primary" disabled>
                <Spinner size="sm">
                </Spinner>
                 {' '}Signing in...
                </Button>}
              </div>
            </form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link
              className="text-light"
              to="/auth/forget-password"
            >
              <small>Forgot password?</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ShowOfficeLogin;
