import { useFetch } from 'hooks/useFetch';
import React, { useEffect, useState } from 'react'
// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Row,
    Spinner,
    Button
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
  import {NotificationContainer, NotificationManager} from 'react-notifications';
  import { Link } from 'react-router-dom';
import { usePost } from 'hooks/usePost';
export default function ViewAllCaptains() {
    const {data, isPending, error}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/captain/getAll');
    const {postData:sendEmail,resData:emailRes,postError:emailError,isPending:emailPending} = usePost();
    const [captainId,setCaptainId]=useState(null);
    
    useEffect(()=>{
      if(error){
        NotificationManager.error(error, 'Error', 3000);
      }
    },[error])

   const handleSendEmail = (captain_id,email,password,f_name,l_name) =>{
      setCaptainId(captain_id);
      const payload = {
        "to" : email,
        "subject" : "Reset Password!",
        "text":`Hello ${f_name}, Please find the link to reset you password, ${process.env.REACT_APP_REACT_SERVER}/auth/reset-password/${captain_id}`
    }
    sendEmail(process.env.REACT_APP_NODE_SERVER+'/api/team/send-email',payload,"POST")
    }
    useEffect(()=>{
      if(emailRes && emailRes.status===200){
        NotificationManager.success("Email sent Successfully!", 'Success', 3000);
      }
      if (emailError) {
        NotificationManager.error(emailError, 'Error', 3000);
        return;
      }
    },[emailRes,emailError])
  return (
    <div className="container pt-8">
      <NotificationContainer />
        <Row>
          <div className="col">
            {<Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">All Team Managers</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Contact</th>
                    <th scope="col">email</th>
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  {data && data.captains.map((captain)=>(<tr key={captain.captain_id}>
                    <th scope="row">
                     <Link to={`/admin/captain-details/${captain.captain_id}`}>{captain.f_name} {captain.l_name}</Link>
                    </th>
                    <td>{captain.contact}</td>
                    <td>
                      {captain.email}
                    </td>
                    <td>
                      {!emailPending && <Button
                        className="float-right"
                        color="default"
                        onClick={()=>handleSendEmail(captain.captain_id,captain.email,captain.password,captain.f_name,captain.l_name)}
                        size="sm"
                      >
                        Send Reset Password
                    </Button>}
                    {emailPending && captainId !== captain.captain_id && <Button
                        className="float-right"
                        color="default"
                        onClick={()=>handleSendEmail(captain.captain_id,captain.email,captain.password,captain.f_name,captain.l_name)}
                        size="sm"
                      >
                        Email
                    </Button>}
                    {emailPending && captainId === captain.captain_id && <Button
                        className="float-right"
                        color="default"
                        disabled
                        size="sm"
                      >
                        Sending...
                    </Button>}
                  </td>
                  </tr>))}
                  {isPending && <tr className='d-flex justify-content-center align-items-center p-4'><td><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></td></tr>}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>}
          </div>
        </Row>
    </div>
  )
}
