import { useFetch } from 'hooks/useFetch';
import React, {  useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Button, Spinner, FormGroup, Input, InputGroup, FormText } from "reactstrap";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useAuthContext } from 'hooks/useAuthContext';
export default function AddRider() {
    const {user} = useAuthContext();
    const [rows,setRows] = useState([
        {rider_name: "",gender: "",DoB: "",age: "", category_id: "",team_id:'',contact_number: "",emergency_contact: "",email: "",rider_photo:""}
    ])
    const {data:categories} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/category/getAll');
    const {data:team} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/team/getByCaptainId/'+user.userId);
    // const {postData,resData,postError} = usePost();
    const navigate = useNavigate();
    const [isLoader,setIsLoder] = useState(false);
    const [isAddRiderPending,setIsAddRiderPending] = useState(false);

    const handleRowChange = (e, currentRow, index, field) =>{
        const updatedRows = [...rows];
        if (field === 'rider_photo') {
          const file = e.target.files[0];
          updatedRows[index] = { ...currentRow, [field]: file };
      }else{
        updatedRows[index] = { ...currentRow, [field]: e.target.value };
      } 
        
        setRows(updatedRows);   
    }

    const handleFormSubmit = async(e) =>{
        e.preventDefault();
        setIsAddRiderPending(true);
        const formData = new FormData();
        // const payload1 = rows.map((row)=>{
        //     return {...row,team_id:team.team.team_id}
        // });
        const payload = rows.map((row) => {
          // Append non-file fields to the FormData
          formData.append('rider_name', row.rider_name);
          formData.append('gender', row.gender);
          formData.append('DoB', row.DoB);
          formData.append('age', row.age);
          formData.append('category_id', row.category_id);
          formData.append('contact_number', row.contact_number);
          formData.append('contact_number', row.contact_number);
          formData.append('emergency_contact', row.emergency_contact);
          formData.append('email', row.email);
          formData.append('rider_photo', row.rider_photo);
          // Return the updated row object
          return {
            ...row,
            team_id: team.team.team_id,
          };
        });
        //console.log(payload);
        formData.append('data', JSON.stringify(payload));

        // postData(process.env.REACT_APP_NODE_SERVER+'/api/rider/add-riders',payload,"POST");
        // Make the API call with the FormData
  try {
    const response = await fetch(
      process.env.REACT_APP_NODE_SERVER + '/api/rider/add-riders',
      {
        method: 'POST',
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error('All the fields are required!!!');
    }

    const responseData = await response.json();
    //console.log(responseData);
    // Handle success response
    if(responseData && responseData.status===200){
        NotificationManager.success("Rider(s) Added Successfully!", 'Success', 3000);
        setIsLoder(true);
        setIsAddRiderPending(false);
        setTimeout(() => {
            handleNavigate();
          }, 3000);
    }
  } catch (error) {
    console.error('Error:', error);
    setIsAddRiderPending(false);
    NotificationManager.error(error.message, 'Error', 3000);
  }
    }
    const handleAddRow = () =>{
        setRows([...rows,{rider_name: "",gender: "",DoB: "",age: "", category_id: "",team_id: "",contact_number: "",emergency_contact: "",email: "",rider_photo:""}])
    }
    const handleNavigate = () =>{
        navigate(`/captain/all-riders/`);
     }

    const handleDeleteRow = (index) =>{
        if (rows.length > 1) {
            const updatedRows = [...rows];
            updatedRows.splice(index, 1);
            setRows(updatedRows);
          }
    }
  return (
    <div className="container pt-8">
    <NotificationContainer/>
    {isLoader && 
            <div className="text-center"> 
                <Spinner color="primary"style={{ height: '3rem', width: '3rem'}}></Spinner>
        </div>}
    {!isLoader && <div>
    <h3 className='mb-3'>Add Rider(s)</h3>
    <form onSubmit={handleFormSubmit} encType="multipart/form-data">
      {rows.map((each, index) => (
        <div className='card p-4 mb-4' key={index}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4>Rider #{index+1}</h4>
            <div className='p-2 text-right'>
                    <svg onClick={()=>handleDeleteRow(index)} style={{'cursor':'pointer'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" className="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                    </svg>
            </div>
            </div>
        <div className="row">
          <div className="col-sm-3">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
              <Input
                      placeholder="Rider Name"
                      type="text"
                      value={each.rider_name}
                      onChange={(e)=>handleRowChange(e, each, index, 'rider_name')}
                    />
              </InputGroup>
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <Input
                      placeholder="Gender"
                      type="select"
                      value={each.gender}
                      onChange={(e)=>handleRowChange(e, each, index, 'gender')}
                >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </Input>
              </InputGroup>
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <Input
                      placeholder="DoB"
                      type="date"
                      value={each.DoB}
                      onChange={(e)=>handleRowChange(e, each, index, 'DoB')}
                />
              </InputGroup>
              <FormText>
                <em>Date of Birth</em>
              </FormText>
            </FormGroup>
            
          </div>
          <div className="col-sm-3">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <Input
                    placeholder="Age"
                    type="number"
                    value={each.age}
                    onChange={(e)=>handleRowChange(e, each, index, 'age')}
                />
              </InputGroup>
            </FormGroup>
          </div>
        </div>
        <div className="row">
            <div className="col-sm-3">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <Input
                    placeholder="Category"
                    type="select"
                    value={each.category_id}
                    onChange={(e)=>handleRowChange(e, each, index, 'category_id')}
                >
                    <option value="">Select Category</option>
                    {categories && categories.categories.map((category) => (
                      <option value={category.category_id} key={category.category_id}>{category.category_name}</option>
                    ))}
                </Input>
                
              </InputGroup>
            </FormGroup>
            </div>
            <div className="col-sm-3">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <Input
                    placeholder="Email"
                    type="email"
                    value={each.email}
                    onChange={(e)=>handleRowChange(e, each, index, 'email')}
                />
              </InputGroup>
            </FormGroup>
            </div>
            <div className="col-sm-3">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <Input
                    placeholder="Contact Number"
                    type="text"
                    value={each.contact_number}
                    onChange={(e)=>handleRowChange(e, each, index, 'contact_number')}
                />
              </InputGroup>
            </FormGroup>
            </div>
            <div className="col-sm-3">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <Input
                    placeholder="Emergency Contact"
                    type="text"
                    value={each.emergency_contact}
                    onChange={(e)=>handleRowChange(e, each, index, 'emergency_contact')}
                />
              </InputGroup>
            </FormGroup>
          </div>
          <div className="col-sm-4">
                        <FormGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                            <Input
                                placeholder="Id Proof"
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleRowChange(e, each, index, 'rider_photo')}
                            />
                            </InputGroup>
                            <FormText>
                                    <em>Rider's Photo</em>
                            </FormText>
                        </FormGroup>
                     </div>
        </div>
        </div>
      ))}
       <Button onClick={handleAddRow} color="primary">
            <svg xmlns="http://www.w3.org/2000/svg" style={{fontWeight:'700'}}width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
            </svg>{' '} 
             Add Rider
        </Button>
       {!isAddRiderPending && <Button color="primary">Submit</Button>}
       {isAddRiderPending && <Button color="primary" disabled>Submitting...</Button>}
      </form>
      </div>}
    </div>
  )
}
