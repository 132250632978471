import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Spinner,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  CardBody,
  Card,
  Row
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useNavigate } from 'react-router-dom';

export default function AddDiscipline() {
  const [eventName, seteventName] = useState("");
  const [eventDesc, seteventDesc] = useState("");
  const [price, setprice] = useState("");
  const {postData,resData,postError,isPending} = usePost();
  const navigate = useNavigate();
  const [isLoader,setIsLoder] = useState(false);
  const handleCreateShow = (e) => {
    e.preventDefault();
    if(eventName === "" || eventDesc === "" || price === "" ){
        NotificationManager.error("All the fields are required!", 'Error', 3000);
        return;
    }
    const payload = {
            "event_name": eventName,
            "event_desc": eventDesc,
            "price": Number(price)
      }
    postData(process.env.REACT_APP_NODE_SERVER+'/api/event/add-event',payload,'POST')  
  };
 const handleNavigate = (event_id,eventName) =>{

    navigate(`/admin/add-sub-discipline/${event_id}`);
 }
  useEffect(()=>{
    if(resData && resData.status===200){
        NotificationManager.success("Discipline Created Successfully!", 'Success', 3000);
        setIsLoder(true);
        seteventDesc('');
        setprice('');
        
        setTimeout(() => {
            handleNavigate(resData.event_id);
          }, 3000);
    }
    if(postError){
        NotificationManager.error(postError, 'Error', 5500);
        return;
    }
  },[resData,postError])



  return (
    <div className="container pt-8">
      <NotificationContainer/>
      {isLoader && 
        <div className="text-center"> 
            <Spinner color="primary"style={{ height: '3rem', width: '3rem'}}></Spinner>
      </div>}
      {!isLoader && <Row>
        <Col lg="3"></Col>
        <Col lg="6" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small><b>ADD DISCIPLINE</b></small>
              </div>
              <form onSubmit={handleCreateShow}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Discipline Title"
                      type="text"
                      value={eventName}
                      onChange={(e) => seteventName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Discipline Price"
                      type="text"
                      value={price}
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Discipline Description"
                      type="textarea"
                      value={eventDesc}
                      onChange={(e) => seteventDesc(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  {!isPending && <Button className="my-4" color="primary" type="submit">
                    Submit
                  </Button>}
                  {isPending && <Button className="my-4" color="primary" disabled>
                    <Spinner size="sm" />
                    {' '}Submitting...
                  </Button>}
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col lg="3"></Col>
      </Row>}
    </div>
  );
}
