import { useFetch } from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Spinner
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
  import {NotificationContainer, NotificationManager} from 'react-notifications';
import { usePost } from "hooks/usePost";
import { useAuthContext } from "hooks/useAuthContext";
export default function HorseDetail() {
    const {horseId}=useParams();
    const {user} = useAuthContext();
    const {data:team} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/team/getByCaptainId/'+user.userId);
    const {data, isPending, error}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/horse/getById/'+horseId);
    const {postData,resData,postError,isPending:updatePending} = usePost();
    const [name,setName]=useState('');
    const [gender,setGender]=useState('');
    const [birthYear,setBirthYear]=useState('');
    const [color,setColor]=useState('');
    const [registrationNo,setRegistrationNo]=useState('');
    const [idProof,setIdProof]=useState('');
    const [isEdit,setIsEdit]=useState(false);

    useEffect(() => {
        if(data){
            setName(data.horse.name);
            setGender(data.horse.gender);
            setBirthYear(data.horse.birth_year);
            setColor(data.horse.color);
            setRegistrationNo(data.horse.registration_no);
            setIdProof(data.horse.id_proof);
        }
    }, [data])
    useEffect(()=>{
        if(error){
          NotificationManager.error(error, 'Error', 3000);
        }
      },[error])

    const handleEdit = () =>{
        //console.log({ name, gender, birthYear, color, registrationNo, idProof });
        const payload = {
            "name": name,
            "gender": gender,
            "birth_year": Number(birthYear),
            "club_name": team.team.team_name,
            "color": color,
            "registration_no": registrationNo,
            "id_proof": idProof,
            "updated_at": new Date()
        }
          postData(process.env.REACT_APP_NODE_SERVER+"/api/horse/update-horse/"+horseId+'/'+team.team.team_id,payload,"PUT");
    }

    useEffect(()=>{
        if(resData && resData.status===200){
            NotificationManager.success(resData.message, 'Success', 3000);
            setIsEdit(false);
        }
        if(postError){
            NotificationManager.error(postError, 'Error', 3000);
        }
    },[resData,postError])
  return (
    <div className="container pt-9">
        <NotificationContainer />
        <Container fluid>
        {isPending && <div className="text-center"><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></div>}
        {!isPending && data && <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
            <Row className="justify-content-center">
                <Col className="order-lg-2 pb-8" lg="3">
                  <div className="card-profile-image">
                    <a href={process.env.REACT_APP_NODE_SERVER+"/"+data.horse.horse_photo} target="_blank" rel="noreferrer" download>
                      <img
                        alt="..."
                        className="rounded-circle"
                        style={{height:"180px", width:"300px",objectFit:'cover'}}
                        src={process.env.REACT_APP_NODE_SERVER+"/"+data.horse.horse_photo}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardBody className="pt-0 pt-md-4">
                <div className="text-center">
                  <h3>
                    {data.horse.name} 
                  </h3>
                  <div className="h5 font-weight-400">
                    <i className="ni location_pin mr-2" />
                    {data.horse.color}, {data.horse.gender}
                  </div>
                  <div className="h5 font-weight-400">
                    <i className="ni location_pin mr-2" />
                    {data.horse.registration_no}
                  </div>
                  <hr className="my-4" />
                  <a href={process.env.REACT_APP_NODE_SERVER+"/"+data.horse.id_proof} target="_blank" rel="noreferrer" download>
                    <img src={process.env.REACT_APP_NODE_SERVER+"/"+data.horse.id_proof} style={{height:"200px", width:"200px",objectFit:'contain'}} alt="" />
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Horse Details</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    {!isEdit && <Button
                      color="primary"
                      onClick={() => setIsEdit(true)}
                      size="sm"
                    >
                      Edit
                    </Button>}
                    {isEdit && <Button
                      color="primary"
                      onClick={handleEdit}
                      size="sm"
                    >
                      Save
                    </Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
              {updatePending && <div className="text-center"><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></div>}
                {!updatePending && <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Horse information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={name}
                            id="input-name"
                            placeholder="Name"
                            type="text"
                            readOnly={!isEdit}
                            onChange={(e)=>setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-birth-year"
                          >
                            Birth Year
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={birthYear}
                            id="input-birth-year"
                            placeholder="Birth Year"
                            type="text"
                            readOnly={!isEdit}
                            onChange={(e)=>setBirthYear(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="contact"
                          >
                            Gender
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={gender}
                            readOnly={!isEdit}
                            onChange={(e)=>setGender(e.target.value)}
                            id="icontact"
                            placeholder="Contact"
                            type="select"
                          >
                            <option value="">select Gender</option>
                            <option value="Mare">Mare</option>
                            <option value="Gelding">Gelding</option>
                            <option value="Stallion">Stallion</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-color"
                          >
                            Color
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-color"
                            placeholder="Color"
                            type="text"
                            value={color}
                            readOnly={!isEdit}
                            onChange={(e)=>setColor(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-registration-no"
                          >
                            Registrastion Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-registration-no"
                            placeholder="Color"
                            type="text"
                            value={registrationNo}
                            readOnly={!isEdit}
                            onChange={(e)=>setRegistrationNo(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-idproof"
                          >
                            Id Proof
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-idproof"
                            placeholder="Color"
                            type="text"
                            value={idProof}
                            readOnly={!isEdit}
                            onChange={(e)=>setIdProof(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>}
              </CardBody>
            </Card>
          </Col>
        </Row>}
      </Container>
    </div>
  )
}
