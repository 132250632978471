import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Spinner,
  FormGroup,
  Input,
  InputGroup,
  Col,
  CardBody,
  Card,
  Row,
  InputGroupAddon,
  InputGroupText,
  Label
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';

export default function RegisterCaptain() {
  const [captainFirstName, setCaptainFirstName] = useState("");
  const [captainLastName, setCaptainLastName] = useState("");
  const [captainEmail, setCaptainEmail] = useState("");
  const [captainContact, setCaptainContact] = useState("");
  const [captainPassword, setCaptainPassword] = useState("");
  const [teamName,setTeamName] = useState('');
  const [teamDesc,setTeamDesc] = useState('');
  const [captainId,setCaptainId] = useState('');
  const [step,setStep] = useState('1');
  const { postData, resData, postError, isPending } = usePost();
  const navigate = useNavigate();
  const {postData:sendEmail,resData:emailRes,postError:emailError} = usePost();
  const [isLoader, setIsLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [teamLogo, setTeamLogo] = useState(null);
  const [createTeamLoading,setCreateTeamLoading] = useState(false);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setTeamLogo(file);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleNavigate = () => {
    navigate('/admin/index');
  }
  const handleRegisterCaptain = (e) => {
    e.preventDefault();
    if (captainFirstName === "" || captainEmail === "" || captainContact === "" || captainPassword === "") {
      NotificationManager.error("All the fields are required!", 'Error', 3000);
      return;
    }
    const payload = {
        "f_name": captainFirstName,
        "l_name": captainLastName,
        "email": captainEmail,
        "contact": captainContact,
        "password": captainPassword,
        "archive": false,
        "created_at": new Date(),
        "updated_at": new Date()
    }
    postData(process.env.REACT_APP_NODE_SERVER+'/api/captain/register', payload, 'POST');
  };
  const handleTeamRegistration = async(e) => {
    e.preventDefault();
    if (teamName === "" || teamDesc === "" || captainId === "") {
      NotificationManager.error("All the fields are required!", 'Error', 3000);
      return;
    }
    setCreateTeamLoading(true);
    const formData = new FormData();
    formData.append("team_name", teamName);
    formData.append("team_desc", teamDesc);
    formData.append("captain_id", captainId);
    formData.append("archive", 0);
    formData.append("created_at", new Date());
    formData.append("updated_at", new Date());
    formData.append("team_logo", teamLogo);
   
    // createTeam(process.env.REACT_APP_NODE_SERVER+'/api/team/create', 'POST');
    try {
      setIsLoader(true);
      const response = await fetch(
        process.env.REACT_APP_NODE_SERVER +'/api/team/create',
        {
          method: 'POST',
          body: formData,
        }
      );
  
      if (!response.ok) {
        throw new Error('All the fields are required!!!');
      }
  
      const responseData = await response.json();
      //console.log(responseData);
      // Handle success response
      if(responseData && responseData.status===200){
          NotificationManager.success("Team Created Successfully!", 'Success', 3000);
          setCreateTeamLoading(false);
          handleSendEmail();
      }
    } catch (error) {
      console.error('Error:', error);
      setCreateTeamLoading(false);
      NotificationManager.error(error.message, 'Error', 3000);
    }
  };
  const handleSendEmail = () =>{
    const payload = {
      "to" : captainEmail,
      "subject" : "Registration done successfully! - Equestrain Show",
      "text":`Please find your credentials below,Team Name: ${teamName} Email: ${captainEmail} Password: ${captainPassword}`
  }
  sendEmail(process.env.REACT_APP_NODE_SERVER+'/api/team/send-email',payload,"POST")
  }
  useEffect(()=>{
    if(emailRes && emailRes.status===200){
      NotificationManager.success("Email sent Successfully!", 'Success', 3000);
      setTimeout(() => {
        setIsLoader(false);
        handleNavigate();
      }, 1000);
    }
    if (emailError) {
      NotificationManager.error(emailError, 'Error', 3000);
      return;
    }
  },[emailRes,emailError])
  
  useEffect(() => {
    if (resData && resData.status === 200) {
      NotificationManager.success("Team Manager Registered Successfully!", 'Success', 3000);
      setCaptainId(resData.userId);
      // setCaptainFirstName('');
      // setCaptainLastName('');
      // setCaptainEmail('');
      // setCaptainContact('');
      // setCaptainPassword('');
      setStep('2')
     }
    if (postError) {
      NotificationManager.error(postError, 'Error', 3000);
      return;
    }
  }, [resData, postError])

  return (
    <div className="container pt-8">
      <NotificationContainer />
      {isLoader &&
        <div className="text-center">
          <Spinner color="primary" style={{ height: '3rem', width: '3rem' }}></Spinner>
          <p>Do not refresh!</p>
          <p>Sending email to the team manager...</p>
        </div>}
      {!isLoader && <Row>
        <Col lg="3"></Col>
        <Col lg="6" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                {step==='1' && <small><b>REGISTER A Team Manager</b></small>}
                {step==='2' && <small><b>REGISTER A TEAM</b></small>}
              </div>
              {step ==="1" && !isLoader && <form onSubmit={handleRegisterCaptain}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="First Name"
                      type="text"
                      value={captainFirstName}
                      onChange={(e) => setCaptainFirstName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Last Name"
                      type="text"
                      value={captainLastName}
                      onChange={(e) => setCaptainLastName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Email"
                      type="email"
                      value={captainEmail}
                      onChange={(e) => setCaptainEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Team Manager Contact"
                      type="number"
                      value={captainContact}
                      onChange={(e) => setCaptainContact(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <Input
                        placeholder="Captain Password"
                        type={showPassword ? 'text' : 'password'}
                        value={captainPassword}
                        onChange={(e) => setCaptainPassword(e.target.value)}
                        />
                        <InputGroupAddon addonType="append">
                            <InputGroupText
                                style={{ cursor: 'pointer' }}
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? '👁️' : '👁️‍🗨️'}
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </FormGroup>
                <div className="text-center">
                  {!isPending && <Button className="my-4" color="primary" type="submit">
                    Register Team Manager
                  </Button>}
                  {isPending && <Button className="my-4" color="primary" disabled>
                    <Spinner size="sm" />
                    {' '}Registering...
                  </Button>}
                </div>
              </form>}
              {step==="2" && !isLoader && <form onSubmit={handleTeamRegistration} encType="multipart/form-data">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Team Name"
                      type="text"
                      value={teamName}
                      onChange={(e) => setTeamName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Team Description"
                      type="textarea"
                      value={teamDesc}
                      onChange={(e) => setTeamDesc(e.target.value)}
                    />
                  </InputGroup>
                  <FormGroup className="mb-3 mt-3">
                    <Label><small>Team Logo</small></Label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </FormGroup>
                <div className="text-center">
                  {!createTeamLoading && <Button className="my-4" color="primary" type="submit">
                    Create Team
                  </Button>}
                  {/* {showEmailBtn && !emailPending && <Button className="my-4" color="primary" type="button" onClick={handleSendEmail}>
                    Send Email
                  </Button>}
                  {showEmailBtn && emailPending && <Button className="my-4" color="primary" type="button" disabled>
                    <Spinner size="sm" /> Sending Email...
                  </Button>} */}
                  {createTeamLoading && <Button className="my-4" color="primary" disabled>
                    <Spinner size="sm" />
                    {' '}Creating...
                  </Button>}
                </div>
              </form>}
            </CardBody>
          </Card>
        </Col>
        <Col lg="3"></Col>
      </Row>}
    </div>
  );
}
