import { useFetch } from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Spinner
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
  import {NotificationContainer, NotificationManager} from 'react-notifications';
import { usePost } from "hooks/usePost";
import ViewAllDisciplines from "./ViewAllDisciplines";
import ViewAllSubDisciplines from "./ViewAllSubDisciplines";
export default function DisciplineDetails() {
    const {disciplineId}=useParams();
    const {data, isPending, error}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/event/getEventById/'+disciplineId);
    const {postData,resData,postError,isPending:updatePending} = usePost();
    const [event_name,setevent_name]=useState('');
    const [price,setprice]=useState('');
    const [event_desc,setevent_desc]=useState('');
    const [isEdit,setIsEdit]=useState(false)
  

    useEffect(() => {
        if(data){
            setevent_name(data.event.event_name);
            setevent_desc(data.event.event_desc);
            setprice(data.event.price)
    
        }
    }, [data])
    useEffect(()=>{
        if(error){
          NotificationManager.error(error, 'Error', 3000);
        }
      },[error])

    const handleEdit = () =>{
        //console.log({event_name,event_desc});
        const payload = {
            "event_name": event_name,
            "event_desc": event_desc,
            'price':Number(price)
          }
          postData(process.env.REACT_APP_NODE_SERVER+"/api/event/update-event/"+disciplineId,payload,"PUT");
    }

    useEffect(()=>{
        if(resData && resData.status===200){
            NotificationManager.success(resData.message, 'Success', 3000);
            setIsEdit(false);
        }
        if(postError){
            NotificationManager.error(postError, 'Error', 3000);
        }
    },[resData,postError])
  return (
    <div className="container pt-7">
        <NotificationContainer />
        <Container fluid>
        {isPending && <div className="text-center"><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></div>}
        {!isPending && data && <Row className="mt-5">
        <Col className="order-xl-3" xl="6"><ViewAllSubDisciplines disciplineId={disciplineId}/></Col>
          <Col className="order-xl-2" xl="6">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Description Details</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    {!isEdit && <Button
                      color="success"
                      onClick={() => setIsEdit(true)}
                      size="sm"
                    >
                      Edit
                    </Button>}
                    {isEdit && <Button
                      color="success"
                      onClick={handleEdit}
                      size="sm"
                    >
                      Save
                    </Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
              {updatePending && <div className="text-center"><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></div>}
                {!updatePending && <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Discipline information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Discipline name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={event_name}
                            id="input-first-name"
                            placeholder="Discipline name"
                            type="text"
                            readOnly={!isEdit}
                            onChange={(e)=>setevent_name(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Discipline Description
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={price}
                            id="input-last-name"
                            placeholder="Discipline Price "
                            type="text"
                            readOnly={!isEdit}
                            onChange={(e)=>setprice(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Discipline Description
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={event_desc}
                            id="input-last-name"
                            placeholder="Discipline Description"
                            type="textarea"
                            readOnly={!isEdit}
                            onChange={(e)=>setevent_desc(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>}
              </CardBody>
            </Card>
          </Col>
        </Row>}
      </Container>
    </div>
  )
}
