import { useFetch } from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Spinner
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
  import {NotificationContainer, NotificationManager} from 'react-notifications';
  import moment from 'moment';
import { usePost } from "hooks/usePost";
import { useAuthContext } from "hooks/useAuthContext";
export default function RiderDetails() {
    const {riderId}=useParams();
    const {user} = useAuthContext();
    const {data:team} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/team/getByCaptainId/'+user.userId);
    const {data, isPending, error}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/rider/getById/'+riderId);
    const {data:categories} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/category/getAll');
    const {postData,resData,postError,isPending:updatePending} = usePost();
    const [riderName, setRiderName] = useState('');
    const [gender, setGender] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [age, setAge] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [emergencyContact, setEmergencyContact] = useState('');
    const [email, setEmail] = useState('');
    const [isEdit,setIsEdit]=useState(false);

    useEffect(() => {
        if(data){
            setRiderName(data.rider.rider_name);
            setGender(data.rider.gender);
            setDateOfBirth(moment.utc(data.rider.DoB).format('yyyy-MM-DD'));
            setAge(data.rider.age);
            setCategoryId(data.rider.category_id);
            setContactNumber(data.rider.contact_number);
            setEmergencyContact(data.rider.emergency_contact);
            setEmail(data.rider.email);
        }
    }, [data])
    useEffect(()=>{
        if(error){
          NotificationManager.error(error, 'Error', 3000);
        }
      },[error])

    const handleEdit = () =>{
        //console.log({ riderName, gender, dateOfBirth, age, categoryId, contactNumber, emergencyContact, email });
        const payload = {
            "rider_name": riderName,
            "gender": gender,
            "DoB": dateOfBirth,
            "category_id": categoryId,
            "age":age,
            "contact_number": contactNumber,
            "emergency_contact": emergencyContact,
            "email": email,
            "updated_at": new Date()
          }
          postData(process.env.REACT_APP_NODE_SERVER+"/api/rider/update-rider/"+riderId+'/'+team.team.team_id,payload,"PUT");
    }

    useEffect(()=>{
        if(resData && resData.status===200){
            NotificationManager.success(resData.message, 'Success', 3000);
            setIsEdit(false);
        }
        if(postError){
            NotificationManager.error(postError, 'Error', 3000);
        }
    },[resData,postError])
  return (
    <div className="container pt-9">
        <NotificationContainer />
        <Container fluid>
        {isPending && <div className="text-center"><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></div>}
        {!isPending && data && <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
            <Row className="justify-content-center">
                  <Col className="order-lg-2 pb-8" lg="3">
                    <div className="card-profile-image">
                      <a href={process.env.REACT_APP_NODE_SERVER+"/"+data.rider.rider_photo} target="_blank" rel="noreferrer" download>
                        <img
                          alt="..."
                          className="rounded-circle"
                          style={{height:"180px", width:"300px",objectFit:'cover'}}
                          src={process.env.REACT_APP_NODE_SERVER+"/"+data.rider.rider_photo}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
              <CardBody className="pt-0 pt-md-4">
                <div className="text-center">
                  <h3>
                    {data.rider.rider_name}, {data.rider.age} 
                  </h3>
                  <div className="h5 font-weight-400">
                    <i className="ni location_pin mr-2" />
                    {data.rider.gender}
                  </div>
                  <div className="h5 font-weight-400">
                    <i className="ni location_pin mr-2" />
                    {data.rider.contact_number}
                  </div>
                  <div className="h5 font-weight-400">
                    <i className="ni location_pin mr-2" />
                    {data.rider.email}
                  </div>
                  <hr className="my-4" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    Show more
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Rider Details</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    {!isEdit && <Button
                      color="primary"
                      onClick={() => setIsEdit(true)}
                      size="sm"
                    >
                      Edit
                    </Button>}
                    {isEdit && <Button
                      color="primary"
                      onClick={handleEdit}
                      size="sm"
                    >
                      Save
                    </Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
              {updatePending && <div className="text-center"><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></div>}
                {!updatePending && <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Rider information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Full Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={riderName}
                            id="input-name"
                            placeholder="Name"
                            type="text"
                            readOnly={!isEdit}
                            onChange={(e)=>setRiderName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-birth-date"
                          >
                            Date of Birth
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={dateOfBirth}
                            id="input-birth-date"
                            placeholder="Birth Date"
                            type="date"
                            readOnly={!isEdit}
                            onChange={(e)=>setDateOfBirth(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-age"
                          >
                            Age
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={age}
                            id="input-age"
                            placeholder="Age"
                            type="number"
                            readOnly={!isEdit}
                            onChange={(e)=>setAge(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-category"
                          >
                            Category
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={categoryId}
                            id="input-category"
                            placeholder="Category"
                            type="select"
                            readOnly={!isEdit}
                            onChange={(e)=>setCategoryId(e.target.value)}
                          >
                            <option value="">Select Category</option>
                            {categories && categories.categories.map((category)=>(<option value={category.category_id} key={category.category_id}>
                                {category.category_name}
                            </option>))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="contact"
                          >
                            Gender
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={gender}
                            readOnly={!isEdit}
                            onChange={(e)=>setGender(e.target.value)}
                            id="icontact"
                            placeholder="Contact"
                            type="select"
                          >
                            <option value="">select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="contact-number"
                          >
                            Contact
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-contact-number"
                            placeholder="Contact Number"
                            type="number"
                            value={contactNumber}
                            readOnly={!isEdit}
                            onChange={(e)=>setContactNumber(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-registration-no"
                          >
                            Emergency Contact
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-registration-no"
                            placeholder="Color"
                            type="number"
                            value={emergencyContact}
                            readOnly={!isEdit}
                            onChange={(e)=>setEmergencyContact(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="Color"
                            type="email"
                            value={email}
                            readOnly={!isEdit}
                            onChange={(e)=>setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>}
              </CardBody>
            </Card>
          </Col>
        </Row>}
      </Container>
    </div>
  )
}
