import { useFetch } from 'hooks/useFetch';
import React, { useEffect, useState } from 'react'
// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Row,
    Spinner,
    Button,
    UncontrolledTooltip,
    Modal, ModalHeader, ModalBody, ModalFooter
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
  import {NotificationContainer, NotificationManager} from 'react-notifications';
  import { Link } from 'react-router-dom';
import { useAuthContext } from 'hooks/useAuthContext';
import { usePost } from 'hooks/usePost';
import placeholderImg from '../../assets/img/theme/placehoder-img.jpg'

export default function GetAllRiders() {
    const {user} = useAuthContext();
    const [teamId,setTeamId]=useState('')
    const {data:team,isPending:teamIsPending} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/team/getByCaptainId/'+user.userId);
    const {data, isPending, error}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/rider/getAll/'+Number(teamId));
    const [modal, setModal] = useState(false);
    const [riderId,setRiderId] = useState(null);
    const {postData,resData,postError,isPending:deletePending} = usePost();
    useEffect(() => {
        if(team){
            setTeamId(team.team.team_id)
        }
    }, [team])

    useEffect(()=>{
      if(error){
        NotificationManager.error(error, 'Error', 3000);
      }
    },[error])

    const toggle = (riderId) => {
      if(!modal){
        setRiderId(riderId);
      }
      setModal(!modal);
    };

    const handleDelete = () =>{
      postData(process.env.REACT_APP_NODE_SERVER+"/api/rider/archive-rider/"+riderId,{},"PUT")
    }

    useEffect(()=>{
      if(resData && resData.status===200){
        NotificationManager.success("Rider deleted successfully!", 'Success', 3000);
        setModal(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      if(postError){
        NotificationManager.error(postError, 'Error', 3000);
      }
    },[resData,postError])
  return (
    <div className="container pt-8">
      <NotificationContainer />
      {riderId && <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete Rider</ModalHeader>
        <ModalBody>
              <div>
                Are you sure you want to delete the rider?
              </div>
        </ModalBody>
        <ModalFooter>
          {!deletePending && <Button color="danger" onClick={handleDelete}>Delete</Button>}
          {deletePending &&<Button color="danger" disabled>Deleting...</Button>}
          <Button onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>}
        <Row>
          <div className="col">
            {<Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">All Riders</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th/>
                    <th scope="col">Name</th>
                    <th scope="col">Category</th>
                    <th scope="col">Gender</th>
                    <th scope="col">DoB</th>
                    <th scope="col">Age</th>
                    <th scope="col">Email</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Emergency Contact</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.riders.map((rider)=>(<tr key={rider.rider_id}>
                    <th><a
                          className="avatar avatar-sm"
                          href={process.env.REACT_APP_NODE_SERVER+"/"+rider.rider_photo}
                          id={`tooltip${rider.rider_id}`}
                          target="_blank" rel="noreferrer" download
                        >
                          <img
                            alt="..."
                            className="rounded-circle"
                            style={{height:"40px", width:"40px",objectFit:'cover'}}
                            src={rider.rider_photo !== "" ?process.env.REACT_APP_NODE_SERVER+"/"+rider.rider_photo : placeholderImg}
                          />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target={`tooltip${rider.rider_id}`}
                        >
                          {rider.rider_name}
                        </UncontrolledTooltip></th>
                    <th scope="row">
                     <Link to={`/captain/rider-details/${rider.rider_id}`}>{rider.rider_name}</Link>
                    </th>
                    <td>{rider.category_name}</td>
                    <td>{rider.gender}</td>
                    <td>{new Date(rider.DoB).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</td>
                    <td>
                      {rider.age}
                    </td>
                    <td>{rider.email}</td>
                    <td>{rider.contact_number}</td>
                    <td>{rider.emergency_contact}</td>
                    <td><i style={{fontSize:"20px",cursor:"pointer"}} onClick={()=>toggle(rider.rider_id)} className="ri-delete-bin-6-line text-danger"></i></td>
                  </tr>))}
                  {teamIsPending && isPending && <tr className='d-flex justify-content-center align-items-center p-4'><td><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></td></tr>}
                </tbody>
              </Table>
              <CardFooter className="py-4">
              {data && data.riders.length===0 && <tr className='m-5'><p>No Rider Are Available! <Link to="/captain/add-rider">Add Riders</Link></p></tr>}
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>}
          </div>
        </Row>
    </div>
  )
}
