import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import CreateShow from "pages/admin/CreateShow";
import RegisterCaptain from "pages/admin/RegisterCaptain";
import ViewAllCaptains from "pages/admin/ViewAllCaptains";
import CaptainDetails from "pages/admin/CaptainDetails";
import ViewAllShows from "pages/admin/ViewAllShows";
import ShowDetails from "pages/admin/ShowDetails";
import CreateEventsCollection from "pages/admin/CreateEventsCollection";
import CaptainLogin from "views/examples/CaptainLogin";
import CaptainIndex from "pages/captain/CaptainIndex";
import AddRider from "pages/captain/AddRider";
import AddHorse from "pages/captain/AddHorse";
import GetAllRiders from "pages/captain/GetAllRiders";
import GetAllHorses from "pages/captain/GetAllHorses";
import HorseDetail from "pages/captain/HorseDetail";
import RiderDetails from "pages/captain/RiderDetails";
import ForgetPassword from "pages/captain/ForgetPassword";
import ResetPassword from "pages/captain/ResetPassword";
import ShowDetail from "pages/captain/ShowDetail";
import EnrollRider from "pages/captain/EnrollRider";
import ViewAllTeam from "pages/admin/ViewAllTeam";
import EnrollmentDetails from "pages/admin/EnrollmentDetails";
import ShowOfficeLogin from "views/examples/ShowOfficeLogin";
import ShowOfficeIndex from "pages/showoffice/ShowOfficeIndex";
import ShowDetailsOffice from "pages/showoffice/ShowDetailsOffice";
import ShowOfficeEnrollmentDetails from "pages/showoffice/ShowOfficeEnrollmentDetails";
import PrintScoringSheets from "pages/showoffice/PrintScoringSheets";
import PrintStartOrder from "pages/showoffice/PrintStartOrder";
import ViewEnrollmentsCaptain from "pages/captain/ViewEnrollmentsCaptain";
import SpectatorIndex from "pages/spectator/SpectatorIndex";
import ShowDetailsSpectator from "pages/spectator/ShowDetailsSpectator";
import EnrollmentDetailsSpectator from "pages/spectator/EnrollmentDetailsSpectator";
import CaptainProfile from "views/examples/CaptainProfile";
import AddDiscipline from "pages/admin/AddDiscipline";
import AddSubDiscipline from "pages/admin/AddSubDiscipline";
import ViewAllDisciplines from "pages/admin/ViewAllDisciplines";
import TeamDetails from "pages/admin/TeamDetails";
import DisciplineDetails from "pages/admin/DisciplineDetails";
import ViewAllSubDisciplines from "pages/admin/ViewAllSubDisciplines";
import Results from "pages/showoffice/Results";
import ResultSpectator from "pages/spectator/ResultSpectator";
import TopTeam from "pages/spectator/TopTeam";
import TopHorse from "pages/spectator/TopHorse";
import TopRiderForCategory from "pages/spectator/TopRiderForCategory";
import AccountsReport from "pages/showoffice/AccountsReport";
import ViewAllHorsesAdmin from "pages/admin/ViewAllHorses";


var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Index />,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: <Icons />,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: <Maps />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/add-sub-discipline/:disciplineId",
    name: "Add Sub Discipline",
    icon: "ni ni-single-02 text-yellow",
    component: <AddSubDiscipline />,
    layout: "/admin",
  },
  {
    path: "/team-details/:teamId",
    name: "Team Details",
    icon: "ni ni-single-02 text-yellow",
    component: <TeamDetails />,
    layout: "/admin",
  },
  {
    path: "/discipline-details/:disciplineId",
    name: "Team Details",
    icon: "ni ni-single-02 text-yellow",
    component: <DisciplineDetails />,
    layout: "/admin",
  },
  {
    path: "/view-sub-disciplines",
    name: "Team Details",
    icon: "ni ni-single-02 text-yellow",
    component: <ViewAllSubDisciplines />,
    layout: "/admin",
  },
  {
    path: "/view-disciplines",
    name: "Add Sub Discipline",
    icon: "ni ni-single-02 text-yellow",
    component: <ViewAllDisciplines />,
    layout: "/admin",
  },
  {
    path: "/add-discipline",
    name: "Add Discipline",
    icon: "ni ni-single-02 text-yellow",
    component: <AddDiscipline />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "Captain Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <CaptainProfile />,
    layout: "/captain",
  },
  {
    path: "/create-show",
    name: "Create A Show",
    icon: "ni ni-single-02 text-yellow",
    component: <CreateShow/>,
    layout: "/admin",
  },
  {
    path: "/view-captains",
    name: "View Captains",
    icon: "ni ni-single-02 text-yellow",
    component: <ViewAllCaptains/>,
    layout: "/admin",
  },
  {
    path: "/view-shows",
    name: "View All Shows",
    icon: "ni ni-single-02 text-yellow",
    component: <ViewAllShows/>,
    layout: "/admin",
  },
  {
    path: "/view-teams",
    name: "View All Team",
    icon: "ni ni-single-02 text-yellow",
    component: <ViewAllTeam/>,
    layout: "/admin",
  },
  {
    path: "/captain-details/:captainId",
    name: "Captain Details",
    icon: "ni ni-single-02 text-yellow",
    component: <CaptainDetails/>,
    layout: "/admin",
  },
  {
    path: "/show-details/:showId",
    name: "Show Details",
    icon: "ni ni-single-02 text-yellow",
    component: <ShowDetails/>,
    layout: "/admin",
  },
  {
    path: "/show-detail/:showId",
    name: "Show Details",
    icon: "ni ni-single-02 text-yellow",
    component:<ShowDetail/>,
    layout: "/captain",
  },
  {
    path: "/register-captain",
    name: "Register Captain",
    icon: "ni ni-single-02 text-yellow",
    component: <RegisterCaptain/>,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <Tables />,
    layout: "/admin",
  },
  {
    path: "/create-event-collection/:showId/:showName",
    name: "Event Collection",
    icon: "ni ni-single-02 text-yellow",
    component: <CreateEventsCollection/>,
    layout: "/admin",
  },
  {
    path: "/view-all-horses",
    name: "View All Horses",
    icon: "ni ni-single-02 text-yellow",
    component: <ViewAllHorsesAdmin/>,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: <Register />,
    layout: "/auth",
  },
  {
    path: "/captain-login",
    name: "Captain Login",
    icon: "ni ni-circle-08 text-pink",
    component: <CaptainLogin/>,
    layout: "/auth",
  },
  {
    path: "/show-office-login",
    name: "Show Login",
    icon: "ni ni-circle-08 text-pink",
    component: <ShowOfficeLogin/>,
    layout: "/auth",
  },
  {
    path: "/captain-index",
    name: "Captain Index",
    icon: "ni ni-circle-08 text-pink",
    component: <CaptainIndex/>,
    layout: "/captain",
  },
  {
    path: "/showoffice-index",
    name: "Captain Index",
    icon: "ni ni-circle-08 text-pink",
    component: <ShowOfficeIndex/>,
    layout: "/showoffice",
  },
  {
    path: "/add-rider",
    name: "Add Rider",
    icon: "ni ni-circle-08 text-pink",
    component: <AddRider/>,
    layout: "/captain",
  },
  {
    path: "/add-horse",
    name: "Add Horse",
    icon: "ni ni-circle-08 text-pink",
    component: <AddHorse/>,
    layout: "/captain",
  },
  {
    path: "/all-riders",
    name: "Get All Rider",
    icon: "ni ni-circle-08 text-pink",
    component: <GetAllRiders/>,
    layout: "/captain",
  },
  {
    path: "/rider-details/:riderId",
    name: "Get Rider Details",
    icon: "ni ni-circle-08 text-pink",
    component: <RiderDetails/>,
    layout: "/captain",
  },
  {
    path: "/all-horses",
    name: "Get All Horses",
    icon: "ni ni-circle-08 text-pink",
    component: <GetAllHorses/>,
    layout: "/captain",
  },
  {
    path: "/horse-details/:horseId",
    name: "Get Rider Details",
    icon: "ni ni-circle-08 text-pink",
    component: <HorseDetail/>,
    layout: "/captain",
  },
  {
    path: "/enrollRiders/:showId/:eventId/:subEventId/:eventCollectionId",
    name: "Enroll Riders",
    icon: "ni ni-single-02 text-yellow",
    component:<EnrollRider/>,
    layout: "/captain",
  },
  {
    path: "/forget-password",
    name: "Reset Password",
    icon: "ni ni-circle-08 text-pink",
    component: <ForgetPassword/>,
    layout: "/auth",
  },
  {
    path: "/reset-password/:captainId",
    name: "Reset Password",
    icon: "ni ni-circle-08 text-pink",
    component: <ResetPassword/>,
    layout: "/auth",
  },
  {
    path: "/index",
    name: "Reset Password",
    icon: "ni ni-circle-08 text-pink",
    component: <SpectatorIndex/>,
    layout: "/spectator",
  },
  {
    path: "/enrollment-details/:showId/:eventId/:subEventId/:eventCollectionId",
    name: "Enrollment Details",
    icon: "ni ni-circle-08 text-pink",
    component: <EnrollmentDetails/>,
    layout: "/admin",
  },
  {
    path: "/view-enrollments/:showId/:eventId/:subEventId/:eventCollectionId",
    name: "Enrollment Details",
    icon: "ni ni-circle-08 text-pink",
    component: <ViewEnrollmentsCaptain/>,
    layout: "/captain",
  },
  {
    path: "/show-details/:showId",
    name: "Show Details",
    icon: "ni ni-circle-08 text-pink",
    component: <ShowDetailsOffice/>,
    layout: "/showoffice",
  },
  
  {
    path: "/enrollment-details/:showId/:eventId/:subEventId/:eventCollectionId",
    name: "Enrollment Details",
    icon: "ni ni-circle-08 text-pink",
    component: <ShowOfficeEnrollmentDetails/>,
    layout: "/showoffice",
  },
  {
    path: "/print-scoringsheets/:eventId/:subeventId/:showId/:categoryId",
    name: "Print Scoring Sheets",
    icon: "ni ni-circle-08 text-pink",
    component: <PrintScoringSheets/>,
    layout: "/showoffice",
  },
  {
    path: "/print-start-order/:eventId/:subeventId/:showId/:categoryId",
    name: "Print Scoring Sheets",
    icon: "ni ni-circle-08 text-pink",
    component: <PrintStartOrder/>,
    layout: "/showoffice",
  },
  {
    path: "/results/:eventId/:subeventId/:showId/:categoryId",
    name: "Results",
    icon: "ni ni-circle-08 text-pink",
    component: <Results/>,
    layout: "/showoffice",
  },
  {
    path: "/reports/:showId/:showName",
    name: "Results",
    icon: "ni ni-circle-08 text-pink",
    component: <AccountsReport/>,
    layout: "/showoffice",
  },
  {
    path: "/results/:eventId/:subeventId/:showId/:categoryId",
    name: " Spectator Results",
    icon: "ni ni-circle-08 text-pink",
    component: <ResultSpectator/>,
    layout: "/spectator",
  },
  {
    path: "/show-details/:showId",
    name: "Show Details",
    icon: "ni ni-circle-08 text-pink",
    component: <ShowDetailsSpectator/>,
    layout: "/spectator",
  },
  {
    path: "/enrollment-details/:showId/:eventId/:subEventId/:eventCollectionId",
    name: "Enrollment Details",
    icon: "ni ni-circle-08 text-pink",
    component: <EnrollmentDetailsSpectator/>,
    layout: "/spectator",
  },
  {
    path: "/top-team",
    name: "Top Team",
    icon: "ni ni-circle-08 text-pink",
    component: <TopTeam/>,
    layout: "/spectator",
  },
  {
    path: "/top-horse",
    name: "Top Horse",
    icon: "ni ni-circle-08 text-pink",
    component: <TopHorse/>,
    layout: "/spectator",
  },
  {
    path: "/top-rider",
    name: "Top Rider",
    icon: "ni ni-circle-08 text-pink",
    component: <TopRiderForCategory/>,
    layout: "/spectator",
  },
];
export default routes;
