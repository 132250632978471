import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { useAuthContext } from 'hooks/useAuthContext';
import "./App.css"
import CaptainLayout from 'layouts/Captain';
import ShowofficeLayout from 'layouts/Showoffice';
import SpectatorLayout from 'layouts/Spectator';
export default function App() {
  const { user,authIsReady }=useAuthContext();
  return (
    <div>
      {authIsReady && <BrowserRouter>
          <Routes>
          {!user && <Route path="/spectator/*" element={<SpectatorLayout />} />}
            {!user && <Route path="/auth/*" element={<AuthLayout />} />}
            {!user && <Route path="*" element={<Navigate to="/spectator/index" replace />} />}
            {/* {!user && <Route path="*" element={<Navigate to="/auth" replace />} />} */}
            {user && user.role==="admin" && <Route path="/admin/*" element={<AdminLayout />} />}
            {user && user.role==="admin" && <Route path="*" element={<Navigate to="/admin/index" replace />} />}
            {user && user.role==="show_office" && <Route path="/showoffice/*" element={<ShowofficeLayout />} />}
            {user && user.role==="show_office" && <Route path="*" element={<Navigate to="/showoffice/showoffice-index" replace />} />}
            {user && user.role==="captain" && <Route path="/captain/*" element={<CaptainLayout/>} />}
            {user && user.role==="captain" && <Route path="*" element={<Navigate to="/captain/captain-index" replace />} />}
          </Routes>
      </BrowserRouter>}
    </div>
  )
}
