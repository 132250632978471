import { useFetch } from 'hooks/useFetch';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Row,
    Spinner,
    Button,
    InputGroup,
    FormGroup,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter,
  } from "reactstrap";
import { usePost } from 'hooks/usePost';
import { useAuthContext } from 'hooks/useAuthContext';
export default function ViewEnrollmentsCaptain() {
    const {user} = useAuthContext();
    const {showId,eventId,subEventId,eventCollectionId} = useParams();
    const [teamId,setTeamId] = useState(null);
    const {data:horses} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/horse/getAll/'+teamId);
    const {data:teamData} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/team/getByCaptainId/'+user.userId);
    const { data:eventCollectionData } = useFetch(process.env.REACT_APP_NODE_SERVER+"/api/event-collection/getEventCollectionById/"+eventCollectionId);
    const {isPending,error,data} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/event-collection/getEventCollectionById/'+eventCollectionId);
    const {data:show} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/show/getById/'+showId);
    const {data:event} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/event/getEventById/'+eventId);
    const {data:subEvent} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/subevent/getSubeventById/'+subEventId);
    const {data:enrollmentsData} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/enrollment/getAllEnrolls/${eventId}/${subEventId}/${showId}/${teamId}?eventcollection_id=${eventCollectionId}`);
    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [enrollmentId,setEnrollmentId] = useState(null);
    const [enrollmentIdForEdit,setEnrollmentIdForEdit] = useState(null);
    const [categoryIds,setCategoryIds] = useState(null);
    const [enrollments,setEnrollments] = useState(null);
    const [isLoading,setIsloading] = useState(false);
    const {postData,resData,postError,isPending:deletePending} = usePost();
    const [horseArr,setHorseArr] = useState(null);
    const [selectedArr,setSelectedArr] = useState([]);
    const [horseId,setHorseId] = useState('');
    const [fullEnrollmentData,setFullEnrollmentData] = useState({});
    const {postData:updateEnrollement,resData:updateRes,postError:updateError,isPending:updateIsPending} = usePost()
    const [bookingsClosed, setBookingsClosed] = useState(false);

    useEffect(()=>{
      if(horses){
        setHorseArr(horses.horses)
      }
    },[horses])
    useEffect(() => {
      if (eventCollectionData && eventCollectionData.eventCollection) {
        const { event_date, event_time } = eventCollectionData.eventCollection;
    
        // Check for valid values
        if (event_date && event_time) {
          // Parse the date
          const parsedDate = new Date(event_date);
    
          // Check if parsedDate is a valid date
          if (!isNaN(parsedDate.getTime())) {
            // Parse the time components
            const parsedTime = event_time.split(':').map(component => parseInt(component, 10));
            
            // Check if parsedTime is valid
            if (!parsedTime.includes(NaN)) {
              // Set the time components to the parsedDate
              parsedDate.setHours(parsedTime[0]);
              parsedDate.setMinutes(parsedTime[1]);
              parsedDate.setSeconds(parsedTime[2] || 0);
    
              // Calculate 4 hours prior to the event time
              const fourHoursPrior = new Date(parsedDate);
              fourHoursPrior.setHours(parsedDate.getHours() - 4);
    
              // Get the current date and time
              const currentDate = new Date();
    
              // Compare dates
              if (currentDate > fourHoursPrior) {
                setBookingsClosed(true);
                console.log('Bookings are closed');
              } else {
                setBookingsClosed(false);
                console.log('Bookings are Open');
              }
            } else {
              console.error('Invalid time components in event_time');
            }
          } else {
            console.error('Invalid date format');
          }
        } else {
          console.error('Missing event date or event time');
        }
      }
    }, [eventCollectionData]);
    useEffect(()=>{
      if(enrollmentsData){
        setSelectedArr(enrollmentsData.enrollments.map((each)=>{
          return {riderId:each.rider_id, horseId:String(each.horse_id)}
        }))
      }
    },[enrollmentsData])
    const toggle = (enrollmentId) => {
      if(!modal){
        setEnrollmentId(enrollmentId);
      }
      setModal(!modal);
    };
    const editModalToggle = (enrollmentId,data) => {
      if(!editModal){
        setEnrollmentIdForEdit(enrollmentId);
        setFullEnrollmentData(data);
      }
      setEditModal(!editModal);
    };
    useEffect(()=>{
        if(eventCollectionData && teamData){
          setCategoryIds(JSON.parse(eventCollectionData.eventCollection.category_ids));
          setTeamId(teamData.team.team_id);
        }
      },[eventCollectionData,teamData])
    useEffect(()=>{
        if(data && data.status === 200){
            setCategoryIds(JSON.parse(data.eventCollection.category_ids))
        }
        if(error){
            NotificationManager.error(error, 'Error', 3000);
        }
    },[data,error])
    
    useEffect(()=>{
      if(resData && resData.status === 200){
        NotificationManager.success('Enrollment Deleted successfully!', 'Success', 3000);
        window.location.reload();
      }
      if(postError){
          NotificationManager.error(postError, 'Error', 3000);
      }
  },[resData,postError])

    useEffect(()=>{
        if(categoryIds !== null){
            fetchEnrollments()
        }
    },[categoryIds])

    const fetchEnrollments = async() => {
        try{    
            setIsloading(true);
            const response = await fetch(`${process.env.REACT_APP_NODE_SERVER}/api/enrollment/getEnrollmentsOfTeam/${eventId}/${subEventId}/${showId}/${teamId}/${eventCollectionId}/?category_ids=${categoryIds.join(',')}`);
            if(!response.ok){
                throw new Error("Something went wrong!")
            }
            const data = await response.json();
            if(data.status !== 200){
                throw new Error("Cannot get data!")
            }
            setEnrollments(data.groupedEnrollments)
            setIsloading(false);
        }catch(err){
            NotificationManager.error(err.message, 'Error', 3000);
        }
    }
    const handleDelete = () =>{
      if(bookingsClosed){
        NotificationManager.error("Cannot delete entry the  4 hours prior to the event!", 'Error', 3000);
        return;
      }
      postData(process.env.REACT_APP_NODE_SERVER+"/api/enrollment/archive-enrollment/"+enrollmentId,{},"PUT")
    }

    const handleUpdateEnrollment = () =>{
      if(horseId === ""){
        NotificationManager.error("Please select a horse!", 'Error', 3000);
      }
      if(bookingsClosed){
        NotificationManager.error("Edits are not available 4 hours prior to the event!", 'Error', 3000);
        return;
      }
      const payload = {...fullEnrollmentData,horse_id:Number(horseId)};
      console.log(payload);
      updateEnrollement(process.env.REACT_APP_NODE_SERVER+"/api/enrollment/update-enrollment/"+enrollmentIdForEdit,payload,'PUT')
    }

    useEffect(()=>{
        if(updateRes && updateRes.status===200){
          NotificationManager.success("Enrollment Updated Successfully!", 'Success', 1200);
          setEditModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        }
        if(updateError){
          NotificationManager.error(updateError, 'Error', 1200);
        }
    },[updateRes,updateError])
    if(isPending && isLoading){
        return (
            <div className='container pt-8 text-center'>
                <NotificationContainer />
                <Spinner
                color="primary"
                style={{ height: "3rem", width: "3rem" }}
                ></Spinner>
            </div>
        )
    }else{
  return (
    <div className='container pt-8'>
        <NotificationContainer />  
        {enrollmentIdForEdit && <Modal isOpen={editModal} toggle={editModalToggle}>
        <ModalHeader toggle={editModalToggle}>Edit Entry</ModalHeader>
        <ModalBody>
              <div>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  
                <Input
                  type="select"
                  onChange={(e)=>setHorseId(e.target.value)}
                >
                  <option value="">Select Horse</option>
                  {horseArr &&
                    horseArr.map((event) => (
                      <option
                        key={event.horse_id}
                        value={event.horse_id}
                        disabled={selectedArr.some(
                          (entry) =>
                            entry.horseId === event.horse_id.toString()
                        )}
                        className={
                          selectedArr.some(
                            (entry) =>
                              entry.horseId === event.horse_id.toString()
                          )
                            ? "text-warning"
                            : "text-success"
                        }
                      >
                       {event.name}
                      </option>
                    ))}
                </Input>
                </InputGroup>
              </FormGroup>
              </div>
        </ModalBody>
        <ModalFooter>
          {!updateIsPending && <Button color="success" onClick={handleUpdateEnrollment}>Update</Button>}
          {updateIsPending &&<Button color="danger" disabled>updating...</Button>}
          <Button onClick={editModalToggle}>Cancel</Button>
        </ModalFooter>
      </Modal>}
        {enrollmentId && <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete Entry</ModalHeader>
        <ModalBody>
              <div>
                Are you sure you want to delete the entry?
              </div>
        </ModalBody>
        <ModalFooter>
          {!deletePending && <Button color="danger" onClick={handleDelete}>Delete</Button>}
          {deletePending &&<Button color="danger" disabled>Deleting...</Button>}
          <Button onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>}
        {show && <h2>Show Name - {show.show.show_name}</h2>}
        {event && subEvent && <h3 className='pb-3'>Enrollments for {event.event.event_name} - ( {subEvent.subevent.subevent_name} )</h3>}
        {enrollments && Object.keys(enrollments).length===0 &&<p className='text-warning'>No Enrollments</p>}
        {enrollments && Object.keys(enrollments).map((categoryId) => (
          <Row key={categoryId} className='mb-5'>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">{enrollments[categoryId][0].category_name}</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>S. NO</th>
                      <th scope="col">Rider Name</th>
                      <th scope="col">Horse Name</th>
                      <th scope="col">Team Name</th>
                      <th></th>
                      <th/>
                    </tr>
                  </thead>
                  <tbody>
                    {enrollments[categoryId].map((enrollment,index) => (
                      <tr key={enrollment.enrollment_id}>
                        <th>{index+1}</th>
                        <th scope="row">{enrollment.rider_name} {enrollment.hc === 1? "(HC)" : ''}</th>
                        <td>{enrollment.name}</td>
                        <td>{enrollment.team_name}</td>
                        <td><i data-toggle="tooltip" data-placement="top" title="Edit" style={{fontSize:"20px",cursor:"pointer"}} onClick={()=>editModalToggle(enrollment.enrollment_id,enrollment)} className="ri-pencil-line text-success"></i></td>
                        <td><i data-toggle="tooltip" data-placement="top" title="Delete" style={{fontSize:"20px",cursor:"pointer"}} onClick={()=>toggle(enrollment.enrollment_id)} className="ri-delete-bin-6-line text-danger"></i></td>
                      </tr>
                    ))}
                    {isPending && <tr className='d-flex justify-content-center align-items-center p-4'><td><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></td></tr>}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    {/* Pagination and other footer content */}
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        ))}     
            </div>
            )
            }
        }
