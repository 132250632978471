import { useFetch } from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Spinner
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
  import {NotificationContainer, NotificationManager} from 'react-notifications';
import { usePost } from "hooks/usePost";
export default function CaptainDetails() {
    const {captainId}=useParams();
    const {data, isPending, error}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/captain/getById/'+captainId);
    const {postData,resData,postError,isPending:updatePending} = usePost();
    const [fname,setFname]=useState('');
    const [lname,setLname]=useState('');
    const [email,setEmail]=useState('');
    const [contact,setContact]=useState('');
    const [isEdit,setIsEdit]=useState(false);

    useEffect(() => {
        if(data){
            setFname(data.captain.f_name);
            setLname(data.captain.l_name);
            setEmail(data.captain.email);
            setContact(data.captain.contact);
        }
    }, [data])
    useEffect(()=>{
        if(error){
          NotificationManager.error(error, 'Error', 3000);
        }
      },[error])

    const handleEdit = () =>{
        //console.log({fname,lname,email,contact});
        const payload = {
            "f_name": fname,
            "l_name": lname,
            "email": email,
            "contact": contact
          }
          postData(process.env.REACT_APP_NODE_SERVER+"/api/captain/updateCaptain/"+captainId,payload,"PUT");
    }

    useEffect(()=>{
        if(resData && resData.status===200){
            NotificationManager.success(resData.message, 'Success', 3000);
            setIsEdit(false);
        }
        if(postError){
            NotificationManager.error(postError, 'Error', 3000);
        }
    },[resData,postError])
  return (
    <div className="container pt-8">
        <NotificationContainer />
        <Container fluid>
        {isPending && <div className="text-center"><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></div>}
        {!isPending && data && <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <CardBody className="pt-0 pt-md-4">
                <div className="text-center">
                  <h3>
                    {data.captain.f_name} {data.captain.l_name} 
                    <span className="font-weight-light">, 27</span>
                  </h3>
                  <div className="h5 font-weight-400">
                    <i className="ni location_pin mr-2" />
                    {data.captain.email}
                  </div>
                  <div className="h5 font-weight-400">
                    <i className="ni location_pin mr-2" />
                    {data.captain.contact}
                  </div>
                  <hr className="my-4" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    Show more
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Captain Details</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    {!isEdit && <Button
                      color="primary"
                      href="#pablo"
                      onClick={() => setIsEdit(true)}
                      size="sm"
                    >
                      Edit
                    </Button>}
                    {isEdit && <Button
                      color="primary"
                      href="#pablo"
                      onClick={handleEdit}
                      size="sm"
                    >
                      Save
                    </Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
              {updatePending && <div className="text-center"><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></div>}
                {!updatePending && <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={fname}
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                            readOnly={!isEdit}
                            onChange={(e)=>setFname(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={lname}
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                            readOnly={!isEdit}
                            onChange={(e)=>setLname(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="contact"
                          >
                            Contact
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={contact}
                            readOnly={!isEdit}
                            onChange={(e)=>setContact(e.target.value)}
                            id="icontact"
                            placeholder="Contact"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="jesse@example.com"
                            type="email"
                            value={email}
                            readOnly={!isEdit}
                            onChange={(e)=>setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>}
              </CardBody>
            </Card>
          </Col>
        </Row>}
      </Container>
    </div>
  )
}
