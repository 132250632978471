import { useFetch } from 'hooks/useFetch';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import * as XLSX from 'xlsx';

export default function AccountsReport() {
  const { showId, showName } = useParams();
  const { data, isPending, error } = useFetch(
    process.env.REACT_APP_NODE_SERVER + '/api/enrollment/getTeamWiseEnrollments/' + showId
  );

  const generateTeamExcel = (teamId, teamName) => {
    if (data && data.teamWiseEnrollments && data.teamWiseEnrollments[teamId]) {
      const sheetData = [
        [
          'S. NO',
          'NAME OF RIDER',
          'HORSE NAME',
          'TEAM NAME',
          'DISCIPLINE',
          'SUB DISCIPLINE',
          'CATEGORY',
          'LATE ENTRY',
          'EVENT COST',
          'PAID(YES/NO)'
        ],
      ];

      data.teamWiseEnrollments[teamId].enrollments.forEach((enrollment, innerIndex) => {
        sheetData.push([
          innerIndex + 1,
          enrollment.rider_name,
          enrollment.horse_name,
          enrollment.team_name,
          enrollment.event_name,
          enrollment.subevent_name,
          enrollment.category_name,
          enrollment.post_entry === 1 ? 'Yes' : 'No',
          `INR ${enrollment.adjusted_price}`,
        ]);
      });

      sheetData.push([
        '',
        '',
        '',
        `Team: ${data.teamWiseEnrollments[teamId].enrollments[0].team_name} - Total: INR ${data.teamWiseEnrollments[teamId].totalPrice}`,
      ]);

      const ws = XLSX.utils.aoa_to_sheet(sheetData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `Final_Report_(${teamName}).xlsx`);
    }
  };

  return (
    <div className='pt-8 container'>
      {isPending && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {data && data.teamWiseEnrollments && (
        Object.keys(data.teamWiseEnrollments).map((teamId) => (
          <div key={teamId} className="mb-5">
            {/* ... existing JSX code for rendering the table ... */}
            <table id={`my-table-${teamId}`} className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">S. NO</th>
                  <th scope="col">NAME OF RIDER</th>
                  <th scope="col">HORSE NAME</th>
                  <th>TEAM NAME</th>
                  <th>DISCIPLINE</th>
                  <th>SUB DISCIPLINE</th>
                  <th>CATEGORY</th>
                  <th>LATE ENTRY</th>
                  <th>EVENT COST</th>
                </tr>
              </thead>
              <tbody>
                {data.teamWiseEnrollments[teamId].enrollments.map((enrollment, innerIndex) => (
                  <tr key={enrollment.enrollment_id}>
                    <td>{innerIndex + 1}</td>
                    <td>{enrollment.rider_name} {enrollment.hc === 1 ? "(HC)":""}</td>
                    <td>{enrollment.horse_name}</td>
                    <td>{enrollment.team_name}</td>
                    <td>{enrollment.event_name}</td>
                    <td>{enrollment.subevent_name}</td>
                    <td>{enrollment.category_name}</td>
                    <td>{enrollment.post_entry === 1 ? "Yes" : "No"}</td>
                    <td>INR {enrollment.adjusted_price}</td>
                  </tr>
                ))}
                <tr>
                  <th colSpan="9">{`Team: ${data.teamWiseEnrollments[teamId].enrollments[0].team_name} - Total: INR ${data.teamWiseEnrollments[teamId].totalPrice}`}</th>
                </tr>
              </tbody>
            </table>
            <Button
              color="success"
              onClick={() =>
                generateTeamExcel(
                  teamId,
                  data.teamWiseEnrollments[teamId].enrollments[0].team_name
                )
              }
            >
              Download Reports for {data.teamWiseEnrollments[teamId].enrollments[0].team_name}
            </Button>
          </div>
        ))
      )}
    </div>
  );
}
