import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Spinner,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  CardBody,
  Card,
  Row
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useNavigate } from 'react-router-dom';

export default function CreateShow() {
  const [showName, setShowName] = useState("");
  const [showDesc, setShowDesc] = useState("");
  const [showStartDate, setShowStartDate] = useState("");
  const [showEndDate, setShowEndDate] = useState("");
  const [showVenue, setShowVenue] = useState("");
  const {postData,resData,postError,isPending} = usePost();
  const navigate = useNavigate();
  const [isLoader,setIsLoder] = useState(false);
  const handleCreateShow = (e) => {
    e.preventDefault();
    if(showName === "" || showDesc === "" || showStartDate === "" || showEndDate === "" || showVenue === "" ){
        NotificationManager.error("All the fields are required!", 'Error', 3000);
        return;
    }
    const payload = {
        "show_name": showName,
        "show_desc": showDesc,
        "show_start_date": showStartDate,
        "show_end_date": showEndDate,
        "show_venue": showVenue,
        "archive": false,
        "created_at": new Date(),
        "updated_at": new Date()
      }
    postData(process.env.REACT_APP_NODE_SERVER+'/api/show/create',payload,'POST')  
  };
 const handleNavigate = (showId,showName) =>{
    navigate(`/admin/create-event-collection/${showId}/${showName}`);
 }
  useEffect(()=>{
    if(resData && resData.status===200){
        NotificationManager.success("Show Created Successfully!", 'Success', 3000);
        setIsLoder(true);
        setShowName('');
        setShowDesc('');
        setShowStartDate('');
        setShowEndDate('');
        setShowVenue('');
        
        setTimeout(() => {
            handleNavigate(resData.showId,resData.showName);
          }, 3000);
    }
    if(postError){
        NotificationManager.error(postError, 'Error', 5500);
        return;
    }
  },[resData,postError])



  return (
    <div className="container pt-8">
      <NotificationContainer/>
      {isLoader && 
        <div className="text-center"> 
            <Spinner color="primary"style={{ height: '3rem', width: '3rem'}}></Spinner>
      </div>}
      {!isLoader && <Row>
        <Col lg="3"></Col>
        <Col lg="6" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small><b>CREATE A SHOW</b></small>
              </div>
              <form onSubmit={handleCreateShow}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Show Title"
                      type="text"
                      value={showName}
                      onChange={(e) => setShowName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Show Venue"
                      type="text"
                      value={showVenue}
                      onChange={(e) => setShowVenue(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="row">
                    <div className="col-sm-6">
                        <FormGroup>
                            <Label><small>Show Date</small></Label>
                            <InputGroup className="input-group-alternative">
                                <Input
                                placeholder="Show Start Date"
                                type="date"
                                value={showStartDate}
                                onChange={(e) => setShowStartDate(e.target.value)}
                                />
                            </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col-sm-6">
                        <FormGroup>
                            <Label><small>Show End Date</small></Label>
                            <InputGroup className="input-group-alternative">
                                <Input
                                placeholder="Show End Date"
                                type="date"
                                value={showEndDate}
                                onChange={(e) => setShowEndDate(e.target.value)}
                                />
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
                <FormGroup>
                  <Label><small>Show Description</small></Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Show Description"
                      type="textarea"
                      value={showDesc}
                      onChange={(e) => setShowDesc(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  {!isPending && <Button className="my-4" color="primary" type="submit">
                    Submit
                  </Button>}
                  {isPending && <Button className="my-4" color="primary" disabled>
                    <Spinner size="sm" />
                    {' '}Submitting...
                  </Button>}
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col lg="3"></Col>
      </Row>}
    </div>
  );
}
