import { useAuthContext } from 'hooks/useAuthContext';
import { useFetch } from 'hooks/useFetch';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Spinner,
  Button,
  UncontrolledTooltip,
  Modal, ModalHeader, ModalBody, ModalFooter,
  FormGroup, Input, InputGroup
} from "reactstrap";
import { usePost } from 'hooks/usePost';
export default function EnrollRider() {
  const {showId, eventId, subEventId, eventCollectionId} = useParams();
  const {user} = useAuthContext();
  const [categoryIds,setCategoryIds] = useState(null);
  const [teamId,setTeamId] = useState(null);
  const [riders,setRiders] = useState(null);
  const [hcRiderId,setHcRiderId] = useState('');
  const [hcHorseId,setHCHorseId] = useState('');
  const [loader,setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [hcModal, sethCModal] = useState(false);
  const [hcCategoryId,setHcCategoryId] = useState(false);
  const [horseArr,setHorseArr] = useState(null);
  const [selectedArr,setSelectedArr] = useState([]);
  const [isHorseSelected,setIsHorseSelected] = useState(null);
  const {data:allTeamrRiders} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/rider/getAll/'+teamId)
  const { data:eventCollectionData } = useFetch(process.env.REACT_APP_NODE_SERVER+"/api/event-collection/getEventCollectionById/"+eventCollectionId);
  const {data:teamData} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/team/getByCaptainId/'+user.userId);
  const {data:horses} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/horse/getAll/'+teamId);
  const {data:show} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/show/getById/'+showId);
  const {data:event} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/event/getEventById/'+eventId);
  const {data:subEvent} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/subevent/getSubeventById/'+subEventId);
  const {data:enrollments} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/enrollment/getAllEnrolls/${eventId}/${subEventId}/${showId}/${teamId}?eventcollection_id=${eventCollectionId}`);
  const [singleRiderData,setsingleRiderData] = useState(null);
  const [riderArr,setRiderArr] = useState([]);
  const [postEntry,setPostEntry]=useState(false);
  const {postData,resData,postError} = usePost();
  const {postData:sendEmail,resData:emailRes,postError:emailErr,isPending:emailIsPending} = usePost();
  const {postData:addHc,resData:hcResData,postError:hcPostError} = usePost();
  const [isLoading,setIsLoading] = useState(false);
  const [bookingsClosed, setBookingsClosed] = useState(false);
  
  useEffect(() => {
    if (show && show.show && show.show.show_start_date) {
      const startDate = new Date(show.show.show_start_date);
      const currentDate = new Date();
      if (currentDate > startDate) {
        setPostEntry(true);
        console.log("post entry")
      }else{
       setPostEntry(false);
       console.log("normal entry")
      }
    }
  }, [show]);

  useEffect(() => {
    if (eventCollectionData && eventCollectionData.eventCollection) {
      const { event_date, event_time } = eventCollectionData.eventCollection;
  
      // Check for valid values
      if (event_date && event_time) {
        // Parse the date
        const parsedDate = new Date(event_date);
  
        // Check if parsedDate is a valid date
        if (!isNaN(parsedDate.getTime())) {
          // Parse the time components
          const parsedTime = event_time.split(':').map(component => parseInt(component, 10));
          
          // Check if parsedTime is valid
          if (!parsedTime.includes(NaN)) {
            // Set the time components to the parsedDate
            parsedDate.setHours(parsedTime[0]);
            parsedDate.setMinutes(parsedTime[1]);
            parsedDate.setSeconds(parsedTime[2] || 0);
  
            // Calculate 4 hours prior to the event time
            const fourHoursPrior = new Date(parsedDate);
            fourHoursPrior.setHours(parsedDate.getHours() - 4);
  
            // Get the current date and time
            const currentDate = new Date();
  
            // Compare dates
            if (currentDate > fourHoursPrior) {
              setBookingsClosed(true);
              console.log('Bookings are closed');
            } else {
              setBookingsClosed(false);
              console.log('Bookings are Open');
            }
          } else {
            console.error('Invalid time components in event_time');
          }
        } else {
          console.error('Invalid date format');
        }
      } else {
        console.error('Missing event date or event time');
      }
    }
  }, [eventCollectionData]);
  
    useEffect(()=>{
    if(enrollments){
      setSelectedArr(enrollments.enrollments.map((each)=>{
        return {riderId:each.rider_id, horseId:String(each.horse_id)}
      }))
     setRiderArr(enrollments.enrollments.map((each)=>{
        return {riderId:each.rider_id, horseId:each.horse_id}
      }),)
    }
  },[enrollments])
  useEffect(()=>{
    if(eventCollectionData && teamData){
      setCategoryIds(JSON.parse(eventCollectionData.eventCollection.category_ids));
      setTeamId(teamData.team.team_id);
    }
  },[eventCollectionData,teamData])

  useEffect(()=>{
    if(horses){
      setHorseArr(horses.horses)
    }
  },[horses])
  const toggle = (data) => {
    if(!modal){
      setsingleRiderData(data);
    }
    setModal(!modal);
  };
  const toggleHCMOdal = (data) => {
    // if(!modal){
    //   setsingleRiderData(data);
    // }
    sethCModal(!hcModal);
  };
  const handleSendEmail = () =>{
    //console.log(isHorseSelected);
    const payload = {
      to:singleRiderData.email,
      subject: `Enrollment Done Successfully! - ${show.show.show_name} `,
      text: `Congratulations ${singleRiderData.rider_name},
      
      You have been successfully enrolled for ${event.event.event_name} - ( ${subEvent.subevent.subevent_name} )
      Horse Name: ${isHorseSelected}
      `
    }
    sendEmail(process.env.REACT_APP_NODE_SERVER+'/api/team/send-email',payload,"POST");
  }
  const fetchRider = async() => {
    try{
      setLoader(true);
      const response = await fetch(`${process.env.REACT_APP_NODE_SERVER}/api/rider/getAllRiders/${teamId}?category_ids=${categoryIds.join(',')}`);
      if(!response.ok){
        throw new Error('Could not connect to the endpoint!');
      }
      const riders = await response.json();
      if(riders.status !==200){
        throw new Error('Unable to fetch rider!')
      }
      setLoader(false);
      //console.log(riders)
      setRiders(riders);
    }catch(err){
      setLoader(false);
      NotificationManager.error(err.message, 'Error', 3000);
    }
    
  }
  useEffect(()=>{
    if(categoryIds && teamId){
      fetchRider();
    }
  },[categoryIds,teamId])

  const handleSelect = (e, riderId) => {
    const selectedHorseId = e.target.value;
    setIsHorseSelected(e.target.options[e.target.selectedIndex].text);
    setSelectedArr((prevSelectedArr) => {
      const existingEntryIndex = prevSelectedArr.findIndex(
        (entry) => entry.riderId === riderId
      );
  
      if (existingEntryIndex !== -1) {
        // If rider already exists in the array, update the selected horse
        const updatedArr = [...prevSelectedArr];
        updatedArr[existingEntryIndex] = { riderId, horseId: selectedHorseId };
        return updatedArr;
      } else {
        // If rider doesn't exist in the array, add a new entry
        return [...prevSelectedArr, { riderId, horseId: selectedHorseId }];
      }
    });
  };

  const handleEnrollment = () => {
    //console.log(singleRiderData);
    //console.log(selectedArr);
    //console.log(isHorseSelected);
    
    if(!isHorseSelected){
      NotificationManager.error("Please select a horse",'Error', 3000);
      return;
    }
    // return;
    let horseId;
    selectedArr.forEach((each)=>{
        if(each.riderId===singleRiderData.rider_id){
          horseId=each.horseId
        }
    })
    const payload= {
      "rider_id": singleRiderData.rider_id,
      "category_id" : singleRiderData.category_id,
      "horse_id": horseId,
      "team_id": teamId,
      "show_id": showId,
      "eventcollection_id":eventCollectionId,
      "event_id": eventId,
      "subevent_id": subEventId,
      "archive": 0,
      "hc":false,
      "post_entry":postEntry,
      "created_at": new Date(),
      "updated_at": new Date()
    }
    //console.log(payload)
    postData(process.env.REACT_APP_NODE_SERVER+"/api/enrollment/add-enrollment",payload,"POST");
  }
  useEffect(() => {
    if(emailRes && emailRes.status===200){
      NotificationManager.success('Rider Enrolled Successfuly!','Success', 3000);
      setModal(false);
      setIsHorseSelected(null);
      handleSendEmail();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    if(emailErr){
      NotificationManager.error("Please select a horse",'Error', 3000);
    }
   }, [emailRes,emailErr])
 useEffect(() => {
  if(resData && resData.status===200){
    // NotificationManager.success('Rider Enrolled Successfuly!','Success', 3000);
    // setModal(false);
    // setIsHorseSelected(null);
    handleSendEmail();
  }
  if(postError){
    NotificationManager.error("Please select a horse",'Error', 3000);
  }
 }, [resData,postError])

 const handleSelectChange = (e) => {
  const selectedValue = e.target.value;

  if (selectedValue) {
    const [selectedRiderId, selectedCategoryId] = selectedValue.split('|');
    
    // Now you have both rider_id and category_id
    console.log('Selected Rider ID:', selectedRiderId);
    console.log('Selected Category ID:', selectedCategoryId);

    // You can set these values in your component state or perform any other actions
    setHcRiderId(selectedRiderId);
    setHcCategoryId(selectedCategoryId);
  }
};

 const HandleHcEntry = () =>{

  if(hcHorseId === '' || hcRiderId === ''){
    NotificationManager.error('All the fields are required!','Error', 1200);
    return
  }
  const payload= {
    "rider_id": hcRiderId,
    "category_id" :hcCategoryId,
    "horse_id": hcHorseId,
    "team_id": teamId,
    "show_id": showId,
    "eventcollection_id":eventCollectionId,
    "event_id": eventId,
    "subevent_id": subEventId,
    "hc":true,
    "post_entry":postEntry,
    "archive": 0,
    "created_at": new Date(),
    "updated_at": new Date()
  }
  console.log(payload)
  setIsLoading(true);
  addHc(process.env.REACT_APP_NODE_SERVER+"/api/enrollment/add-enrollment",payload,"POST");
  
 }

 useEffect(()=>{
  if(hcResData && hcResData.status===200){
    NotificationManager.success('HC entry added Successfuly!','Success', 1200);
    setTimeout(() => {
      setIsLoading(false)
      window.location.reload();
    }, 1200);
  }
  if(hcPostError){
    NotificationManager.error(hcPostError,'Error', 3000);
    setIsLoading(false);
  }
 },[hcResData,hcPostError])
  if(loader){
    return(
      <div className="text-center container pt-8">
        <NotificationContainer/>
        <Spinner
          color="primary"
          style={{ height: "3rem", width: "3rem" }}
        ></Spinner>
      </div>
    )
  }
  else{
    return (
      <div className="container pt-8">
        <NotificationContainer/>
        {/* Edit Modal */}
        {allTeamrRiders && <Modal isOpen={hcModal} toggle={toggleHCMOdal}>
        <ModalHeader toggle={toggleHCMOdal}>Enroll Hors Concours</ModalHeader>
        <ModalBody>
            {!isLoading && <div>
              {postEntry && <p className='text-danger'>This is a late  entry, as show has been already started!</p>}
            <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                <Input
                  type="select"
                  onChange={(e) => handleSelectChange(e)}
                >
                  <option value="">Select Rider</option>
                  {allTeamrRiders &&
                    allTeamrRiders.riders.map((each) => (
                      <option
                        key={each.rider_id}
                        value={`${each.rider_id}|${each.category_id}`}
                        disabled={selectedArr.filter(entry => entry.riderId === each.rider_id).length >= 2}
                      className={selectedArr.filter(entry => entry.riderId === each.rider_id).length >= 2 ? "text-warning" : "text-success"}
                      >
                       {each.rider_name}
                      </option>
                    ))}
                </Input>
                </InputGroup>
              </FormGroup>
            <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  
                <Input
                  type="select"
                  onChange={(e) => setHCHorseId(e.target.value)}
                >
                  <option value="">Select Horse</option>
                  {horseArr &&
                    horseArr.map((event) => (
                      <option
                        key={event.horse_id}
                        value={event.horse_id}
                        disabled={selectedArr.some(
                          (entry) =>
                            entry.horseId === event.horse_id.toString()
                        )}
                        className={
                          selectedArr.some(
                            (entry) =>
                              entry.horseId === event.horse_id.toString()
                          )
                            ? "text-warning"
                            : "text-success"
                        }
                      >
                       {event.name}
                      </option>
                    ))}
                </Input>
                </InputGroup>
              </FormGroup>
            </div>}
            {isLoading && <div className="text-center">
                    <Spinner color="primary" style={{ height: '3rem', width: '3rem' }}></Spinner>
                    <p>Redirecting...</p>
                  </div>}
        </ModalBody>
        <ModalFooter>
          {!emailIsPending &&<Button onClick={HandleHcEntry} color="primary">
            Enroll
          </Button>}
          {emailIsPending && <Button color="primary" disabled>
          <Spinner size="sm" />{' '}Enrolling...
          </Button>}
          <Button color="secondary" onClick={toggleHCMOdal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>}
      {singleRiderData && <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Enroll {singleRiderData.rider_name}</ModalHeader>
        <ModalBody>
            <div>
              {postEntry && <p className='text-danger'>This is a late  entry, as show has been already started!</p>}
            {!emailIsPending &&<FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                <Input
                  type="select"
                  onChange={(e) => handleSelect(e,singleRiderData.rider_id)}
                >
                  <option value="">Select Horse</option>
                  {horseArr &&
                    horseArr.map((event) => (
                      <option
                        key={event.horse_id}
                        value={event.horse_id}
                        disabled={selectedArr.some(
                          (entry) =>
                            entry.horseId === event.horse_id.toString()
                        )}
                        className={
                          selectedArr.some(
                            (entry) =>
                              entry.horseId === event.horse_id.toString()
                          )
                            ? "text-warning"
                            : "text-success"
                        }
                      >
                       {event.name}
                      </option>
                    ))}
                </Input>
                </InputGroup>
              </FormGroup>}
              <div className="text-center">
             { emailIsPending && <Spinner
                color="primary"
                style={{ height: "3rem", width: "3rem" }}
              ></Spinner>}
              </div>
            </div>
        </ModalBody>
        <ModalFooter>
          {!emailIsPending &&<Button onClick={handleEnrollment} color="primary">
            Enroll
          </Button>}
          {emailIsPending && <Button color="primary" disabled>
          <Spinner size="sm" />{' '}Enrolling...
          </Button>}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>}
      {show && <h2>Show Name - {show.show.show_name}</h2>}
        {event && subEvent && <h3 className='pb-3'>Enroll Riders for {event.event.event_name} - ( {subEvent.subevent.subevent_name} )</h3>}
        {bookingsClosed && <p className='text-danger'>Bookings are closed!</p>}
        <Row>
          <div className="col">
            {<Card className="shadow">
              <CardHeader className="border-0">
                <div className='d-flex'>
                <h3 className="mb-0">Eligible Riders</h3>
                <Button className="ml-auto" color='primary' disabled={bookingsClosed} onClick={toggleHCMOdal}>Add HC</Button>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th/>
                    <th scope="col">Name</th>
                    <th scope="col">Category</th>
                    <th scope="col">Age</th>
                    <th scope="col">Email</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Emergency Contact</th>
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  {riders && riders.riders.map((rider)=>(<tr key={rider.rider_id}>
                    <th><a
                          className="avatar avatar-sm"
                          href={process.env.REACT_APP_NODE_SERVER+"/"+rider.rider_photo}
                          id={`tooltip${rider.rider_id}`}
                          target="_blank" rel="noreferrer" download
                        >
                          <img
                            alt="..."
                            className="rounded-circle"
                            style={{height:"40px", width:"40px",objectFit:'cover'}}
                            src={process.env.REACT_APP_NODE_SERVER+"/"+rider.rider_photo}
                          />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target={`tooltip${rider.rider_id}`}
                        >
                          {rider.rider_name}
                        </UncontrolledTooltip></th>
                    <th scope="row">
                     <Link to={`/captain/rider-details/${rider.rider_id}`}>{rider.rider_name}</Link>
                    </th>
                    <td>{rider.category_name}</td>
                    {/* <td>{new Date(rider.DoB).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</td> */}
                    <td>
                      {rider.age}
                    </td>
                    <td>{rider.email}</td>
                    <td>{rider.contact_number}</td>
                    <td>{rider.emergency_contact}</td>
                    {/* <td>{!riderArr.some(
                          (entry) =>
                            entry.riderId === rider.rider_id
                        ) ? (<Button
                        className="float-right"
                        color="default"
                        size="sm"
                        onClick={()=>toggle(rider)}
                      >
                        Enroll
                    </Button>) :(<Button
                        className="float-right"
                        color="default"
                        size="sm"
                        disabled
                      >
                        Enrolled
                    </Button>) }
                    </td> */}
                   <td>
                      {riderArr.filter(entry => entry.riderId === rider.rider_id).length >= 2 ? (
                        <Button
                          className="float-right"
                          color="default"
                          size="sm"
                          disabled
                        >
                          Double Entry
                        </Button>
                      ) : (
                        <Button
                          className="float-right"
                          color="default"
                          size="sm"
                          disabled={bookingsClosed}
                          onClick={() => toggle(rider)}
                        >
                        {riderArr.filter(entry => entry.riderId === rider.rider_id).length>0? "Enroll Again" : "Enroll"}
                        </Button>
                      )}
                    </td>
                    {/* <td>{riderArr.filter(entry => entry.riderId === rider.rider_id).length}</td> */}
                  </tr>))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
              {riders && riders.riders.length===0 && <tr className='m-5'><p>No Rider With the Matching Category Available! <Link to="/captain/add-rider">Add Riders</Link></p></tr>}
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>}
          </div>
        </Row>
      </div>
    )
  }
}
