import { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import { useFetch } from "hooks/useFetch";
import { Link } from "react-router-dom";

const Index = (props) => {
  const {data, isPending}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/team/getAll');
  const {data:shows}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/show/getAll');

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0 order-xl-2"  xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Teams</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="success"
                      href="/admin/view-teams"
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th></th>
                    <th scope="col">Team Name</th>
                    <th scope="col">Captain</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.teams.slice(0, 5).map((team)=>(<tr key={team.team_id}>
                    <th>
                    <a
                          className="avatar avatar-sm"
                          href={process.env.REACT_APP_NODE_SERVER+"/uploads/"+team.team_logo}
                          id={`tooltip${team.team_id}`}
                          target="_blank" rel="noreferrer" download
                        >
                          <img
                            alt="..."
                            className="rounded-circle"
                            style={{height:"40px", width:"40px",objectFit:'cover'}}
                            src={process.env.REACT_APP_NODE_SERVER+"/uploads/"+team.team_logo}
                          />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target={`tooltip${team.team_id}`}
                        >
                          {team.team_name}
                        </UncontrolledTooltip>
                    </th>
                    <th scope="row">
                     <Link to={`/admin/team-details/${team.team_id}`}>{team.team_name}</Link>
                    </th>
                    <td><Link to={`/admin/captain-details/${team.captain_id}`}>{team.captain_names.slice(0,15)}...</Link></td>
                  {isPending && <tr className='d-flex justify-content-center align-items-center p-4'><td><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></td></tr>}
                  </tr>))}
                </tbody>
              </Table>
              {data && data.teams.length ===0 && <p className=" p-3 text-danger">No Teams are Available! <Link to={`/admin/register-captain`}>Create a Team</Link></p>}
            </Card>
          </Col>
          <Col xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Shows</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="success"
                      href="/admin/view-shows"
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Venue</th>
                    <th scope="col">Show Date</th>
                  </tr>
                </thead>
                <tbody>
                {shows && shows.shows.slice(0, 5).map((show)=>(<tr key={show.show_id}>
                  <th scope="row">
                     <Link to={`/admin/show-details/${show.show_id}`}>{show.show_name}</Link>
                    </th>
                    <td>{show.show_venue}</td>
                    <td>
                      {new Date(show.show_start_date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric'})}
                      {' '}to{' '}
                      {new Date(show.show_end_date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric'})}
                    </td>
                  </tr>))}
                </tbody>
              </Table>
              {shows && shows.shows.length ===0 && <p className=" p-3 text-danger">No Shows are Available! <Link to={`/admin/create-show`}>Create a Show</Link></p>}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
