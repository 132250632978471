// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Spinner
  } from "reactstrap";
  // core components
import { useAuthContext } from "hooks/useAuthContext";
import { useFetch } from "hooks/useFetch";
import { useEffect, useState } from "react";
import { usePost } from "hooks/usePost";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
  
  const CaptainProfile = () => {
    const {user} = useAuthContext();
    const {data,isPending} = useFetch(process.env.REACT_APP_NODE_SERVER+"/api/captain/getById/"+user.userId);
    const [isEdit,setISEdit] = useState(false);
    const [captain,setCaptain] = useState(null);
    const {postData,resData,postError,isPending:updateCaptainPending} = usePost();

    useEffect(()=>{
        if(data && data.status===200){
            setCaptain(data.captain);
        }
    },[data])
    const handleUpdateCaptain = () =>{

        const payload = {
            "f_name": captain.f_name,
            "l_name": captain.l_name,
            "email": captain.email,
            "contact": captain.contact
          }
          //console.log(payload);
          
        postData(process.env.REACT_APP_NODE_SERVER+"/api/captain/updateCaptain/"+user.userId,payload,"PUT");
    }
    useEffect(() => {
        if(resData && resData.status===200){
            NotificationManager.success('Captain Updated Successfully!', 'Success', 3000);
        }
        if(postError){
            NotificationManager.error(postError, 'Error', 3000);
        }
    }, [resData,postError])
    return (
      <>
      <NotificationContainer />
       {(isPending) && <div className='container text-center pt-9'>
         <Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner>
        </div>}
       {data && captain && <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundImage:
            "url(" + require("../../assets/img/theme/profile-cover.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="10">
              <h1 className="display-2 text-white">Welcome, {data.captain.f_name} {data.captain.l_name}</h1>
              <p className="text-white mt-0 mb-5">
                This is your profile page. 
              </p>
              <Button
                color="info"
                href="#pablo"
                onClick={() => setISEdit(!isEdit)}
              >
                Edit profile
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
   
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="2"></Col>
            <Col className="order-xl-3" xl="2"></Col>
            <Col className="order-xl-2" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                    {!isEdit && <Col className="text-right" xs="4">
                      <Button
                        color="success"
                        href="#pablo"
                        onClick={() => setISEdit(!isEdit)}
                        size="sm"
                      >
                        Edit
                      </Button>
                    </Col>}
                   {isEdit && <Col className="text-right" xs="4">
                      <Button
                        color="success"
                        href="#pablo"
                        onClick={handleUpdateCaptain}
                        size="sm"
                      >
                        Save
                      </Button>
                    </Col>}
                  </Row>
                </CardHeader>
                <CardBody> 
                {(updateCaptainPending) && <div className='container text-center'>
         <Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner>
        </div>}
                 { !updateCaptainPending &&<Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              First name
                            </label>
                            <Input
                              className="form-control-alternative"
                              readOnly={!isEdit}
                              value={captain.f_name}
                              onChange={(e)=>setCaptain({...captain,f_name:e.target.value})}
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              readOnly={!isEdit}
                              value={captain.l_name}
                              onChange={(e)=>setCaptain({...captain,l_name:e.target.value})}
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-contact"
                            >
                              Contact
                            </label>
                            <Input
                              className="form-control-alternative"
                              readOnly={!isEdit}
                              value={captain.contact}
                              onChange={(e)=>setCaptain({...captain,contact:e.target.value})}
                              id="input-contact"
                              placeholder="Contact"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              readOnly={!isEdit}
                              id="input-email"
                              placeholder="jesse@example.com"
                              type="email"
                              value={captain.email}
                              onChange={(e)=>setCaptain({...captain,email:e.target.value})}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    </Form>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>}
      </>
    );
  };
  
  export default CaptainProfile;
  