import { useFetch } from 'hooks/useFetch'
import React from 'react'
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import {
   Button
  } from "reactstrap";
export default function ViewAllHorsesAdmin() {
    const {data} = useFetch(process.env.REACT_APP_NODE_SERVER+'/api/horse/getAll')
    const generatePDF = () => {
        if(data){
        const doc = new jsPDF({
            orientation: 'landscape', // Set orientation to landscape
          });
          doc.text(`HORSE LIST: JAPALOUPPE ANNUAL EQUESTRIAN SHOW `, 20, 10);
    
        // Set the Y coordinate for the third line
        doc.setDrawColor(0); // reset draw color (black)
        doc.setFillColor(255); // set fill color to white
        doc.setLineWidth(0.5); 
        doc.setLineWidth(0.3);// set line width
        // doc.line(20, 60, 190, 60); // Horizontal line as separator
        doc.setFontSize(10);
          
        const tableData = {
            html: '#my-table',
            theme: 'grid', 
            startY: 40,// Specify the theme here
            styles: {
              // You can also customize other styles here
              fontSize: 8,
              fontStyle: 'normal',
              textColor: [0, 0, 0],
              cellPadding: 2,
              lineColor: [0, 0, 0],
            },
            headerStyles: {
              fillColor: [211, 211, 211], // Light grey color
            },
          };
        
          doc.autoTable(tableData);
        // Save the PDF
        doc.save(`Horse_List.pdf`);
    }
      };
  return (
    <div className='pt-8 container'>
        <div className='mb-4'><Button color="success" onClick={generatePDF}>Download PDF</Button></div>
        <table id="my-table" className="table table-bordered">
            <thead>
                <tr>
                <th scope="col">S. NO</th>
                <th scope="col">HORSE NAME</th>
                <th scope="col">GENDER</th> 
                <th scope="col">BIRTH YEAR</th>
                <th scope="col">COLOR</th> 
                <th scope="col">TEAM</th>    
                </tr>
            </thead>
            <tbody>
                {data && data.horses.map((each, index) => (
                <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{each.name}</td>
                    <td>{each.gender}</td>
                    <td>{each.birth_year}</td>
                    <td>{each.color}</td>
                    <td>{each.team_name}</td>
                </tr>
                ))}
                
            </tbody>
        </table>
    </div>
  )
}
