import React, { useEffect, useState } from "react";
import { Button, Spinner, FormGroup, Input, InputGroup } from "reactstrap";
import { MultiSelect } from 'primereact/multiselect';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { usePost } from "hooks/usePost";
import { useFetch } from "hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export default function CreateEventsCollection() {
    const {showId,showName} = useParams();
  const { data } = useFetch(process.env.REACT_APP_NODE_SERVER + '/api/event/getAllEvents');
  const [rows, setRows] = useState([{ event_id: '', subevent_id: '', category_ids: [],event_date:'',event_time:''}]);
  const [eventId,setEventId]=useState('');
  const [isLoader,setIsLoder] = useState(false);
  const navigate = useNavigate();
  const { data: subeventData, isPending: subeventIsPending } = useFetch(
    process.env.REACT_APP_NODE_SERVER + '/api/subevent/getSubeventByEventId/'+Number(eventId)
  );
  const { data: categories, isPending: categoriesIsPending } = useFetch(
    process.env.REACT_APP_NODE_SERVER + '/api/category/getAll'
  );
  const [categoriesArr, setCategoriesArr] = useState([]);
  const { postData, resData, postError, isPending: postIsPending } = usePost();

  useEffect(() => {
    if (categories && categories.status === 200) {
      const mappedCategories = categories.categories.map((each) => {
        return { category: each.category_name, code: each.category_id };
      });
      setCategoriesArr(mappedCategories);
    }
  }, [categories]);
 
  const handleCreateEventsCollection = async (e) => {
    e.preventDefault();
    //console.log(rows);
    const payload = {
        rows: rows.map((each) => ({
          show_id: Number(showId),
          event_id: each.event_id,
          subevent_id: each.subevent_id,
          category_ids: each.category_ids.map((category) => category.code),
          event_date:each.event_date,
          event_time:each.event_time
        })),
      };
    // console.log(payload.rows)
    postData(process.env.REACT_APP_NODE_SERVER + '/api/event-collection/add-event-collections', payload.rows, "POST");
  };

  const handleAddRow = () => {
    setRows([...rows, { event_id: '', subevent_id: '', category_ids: [] }]);
  };
  const handleRowChange = (e, currentRow, index, field) => {
    //console.log(e.target.value,"hi")
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      if (field === 'event_id') {
        updatedRows[index] = {
          ...currentRow,
          event_id: Number(e.target.value),
        };
        setEventId(e.target.value)
      }
      if (field === 'subevent_id') {
        updatedRows[index] = {
          ...currentRow,
          subevent_id: Number(e.target.value),
        };
      }
      if (field === 'category_ids') {
        updatedRows[index] = {
          ...currentRow,
          category_ids: e.value,
        };
      }
      if (field === 'category_ids') {
        updatedRows[index] = {
          ...currentRow,
          category_ids: e.value,
        };
      }
      if (field === 'event_time') {
        updatedRows[index] = {
          ...currentRow,
          event_time: e.target.value,
        };
      }
      if (field === 'event_date') {
        updatedRows[index] = {
          ...currentRow,
          event_date: e.target.value,
        };
      }
      //console.log(updatedRows);
      return updatedRows;
    });
  };
  const handleDeleteRow = (index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows.splice(index, 1);
      return updatedRows;
    });
  };
  const handleNavigate = () =>{
    navigate(`/admin/show-details/${showId}`);
 }
  useEffect(()=>{
    if(resData && resData.status===200){
        NotificationManager.success("Events Added Successfully!", 'Success', 3000);
        setIsLoder(true);
        setTimeout(() => {
            handleNavigate();
          }, 3000);
    }
    if(postError){
        NotificationManager.error(postError, 'Error', 5500);
        return;
    }
  },[resData,postError])
  return (
    <div className="container pt-8">
        <NotificationContainer/>
        {isLoader && 
            <div className="text-center"> 
                <Spinner color="primary"style={{ height: '3rem', width: '3rem'}}></Spinner>
        </div>}
        {!isLoader && <>
      <div className="text-center text-muted mb-4">
        <small style={{'textTransform':"capitalize"}} className="text-uppercase"><b>ADD EVENT(S) FOR {showName}</b></small>
      </div>
      <form onSubmit={handleCreateEventsCollection}>
        {rows.map((each, index) => (
          <div className="card  p-4 mb-4">
          <div className="row" key={index}>
            <div className="col-sm-12 pb-3 text-right">
              <svg onClick={()=>handleDeleteRow(index)} style={{'cursor':'pointer'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" className="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
              </svg>
            </div>
            <div className="col-sm-4">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <Input
                    // onChange={(e) => {setRows((prevRows) => updateRow(prevRows, index, 'event_id', e.target.value))}}
                    onChange={(e)=>handleRowChange(e, each, index,'event_id')}
                    type="select"
                    name={`selectevent-${index}`}
                    id={`selectevent-${index}`}
                    value={Number(each.event_id)}
                  >
                    <option value="">Select Event</option>
                    {data && data.events.map((event) => (
                      <option value={event.event_id} key={event.event_id}>{event.event_name}</option>
                    ))}
                  </Input>
                </InputGroup>
              </FormGroup>
            </div>
            <div className="col-sm-4">
              {!subeventIsPending && (
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                    //   onChange={(e) => setRows((prevRows) => updateRow(prevRows, index, 'subevent_id', e.target.value))}
                    onChange={(e)=>handleRowChange(e, each, index,'subevent_id')}
                      type="select"
                      name={`selectsubevent-${index}`}
                      id={`selectsubevent-${index}`}
                      value={each.subevent_id || ''}
                    >
                      <option value="">Select Sub Event</option>
                      {subeventData && subeventData.subevent.map((subevent) => (
                        <option value={subevent.subevent_id} key={subevent.subevent_id}>{subevent.subevent_name}</option>
                      ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              )}
            </div>
            <div className="col-sm-4">
              {!categoriesIsPending && (
                <div className="card flex justify-content-center">
                  <MultiSelect
                    style={{ 'fontSize': '14px', 'color': '#8898aa', 'fontFamily': 'Open Sans' }}
                    value={each.category_ids}
                    // onChange={(e) => setRows((prevRows) => updateRow(prevRows, index, 'category_ids', e.value))}
                    onChange={(e)=>handleRowChange(e, each, index,'category_ids')}
                    options={categoriesArr}
                    optionLabel="category"
                    placeholder="Select Categories"
                    maxSelectedLabels={3}
                    className="input-group-alternative"
                  />
                </div>
              )}
            </div>
            <div className="col-sm-4">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <Input
                    // onChange={(e) => {setRows((prevRows) => updateRow(prevRows, index, 'event_id', e.target.value))}}
                    onChange={(e)=>handleRowChange(e, each, index,'event_date')}
                    type="date"
                    name={`selectevent-${index}`}
                    id={`selectevent-${index}`}
                    value={(each.event_date)}
                  />
                </InputGroup>
              </FormGroup>
            </div>
            <div className="col-sm-4">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <Input
                    // onChange={(e) => {setRows((prevRows) => updateRow(prevRows, index, 'event_id', e.target.value))}}
                    onChange={(e)=>handleRowChange(e, each, index,'event_time')}
                    type="time"
                    name={`selectevent-${index}`}
                    id={`selectevent-${index}`}
                    value={(each.event_time)}
                  />
                </InputGroup>
              </FormGroup>
            </div>
          </div>
          </div>
        ))}
            <div className="text-center">
              {!postIsPending && (
                <Button color="primary" type="button" onClick={handleAddRow}>
                  <i className="ni ni-fat-add"></i>Add
                </Button>
              )}
              {!postIsPending && (
                <Button color="primary" type="submit">
                  Submit
                </Button>
              )}
              {postIsPending && (
                <Button color="primary" disabled>
                  <Spinner size="sm" />
                  {' '}Submitting...
                </Button>
              )}
            </div>
      </form>
      </>}
    </div>
  );
}
// const updateRow = (rows, index, field, value) => {
//   return rows.map((row, i) => (i === index ? { ...row, [field]: value } : row));
// };
