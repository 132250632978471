import {useState,useEffect} from "react"
export const  useFetch = (url) => {
    const [isPending,setIsPending]=useState(false);
    const [error,setError]=useState(null);
    const [data,setData]=useState(null);
  useEffect(()=>{
    const fetchData=async()=>{
        try{
            setError(null);
            setIsPending(true);
        const response=await fetch(url);
        //console.log(response)
        if(response.status!==200){
            throw new Error("Oops! Something went wrong.");
        }
        const resData=await response.json();
        console.log(resData)
        setData(resData);
        setIsPending(false);
        }catch(err){
            setError(err.message);
            setIsPending(false);
        }
    }
    fetchData();
  },[url])
  return{isPending,error,data}
}
 

