import { useFetch } from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Container,
    Row,
    Col,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
  import {NotificationContainer, NotificationManager} from 'react-notifications';
import { usePost } from "hooks/usePost";

export default function TeamDetails() {
    const {teamId}=useParams();
    const {data, isPending, error}=useFetch(process.env.REACT_APP_NODE_SERVER+'/api/team/getById/'+teamId);
    const {postData,resData,postError,isPending:updatePending} = usePost();
    const [team_name,setteam_name]=useState('');
    const [captain_id,setcaptain_id]=useState('');
    const [team_desc,setteam_desc]=useState('');
    const [team_id, setTeamId] = useState(null);
    const [modal, setModal] = useState(false)
    const [isEdit,setIsEdit]=useState(false)
    const [captainFirstName, setCaptainFirstName] = useState("");
    const [captainLastName, setCaptainLastName] = useState("");
    const [captainEmail, setCaptainEmail] = useState("");
    const [captainContact, setCaptainContact] = useState("");
    const [captainPassword, setCaptainPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const {postData:createTm,resData:tmRes,postError:tmErr} = usePost();
    const {postData:joinTm,resData:joinTmRes,postError:joinTmErr} = usePost();
    const {postData:sendEmail,resData:emailRes,postError:emailError} = usePost();
    const [isLoader, setIsLoader] = useState(false);


    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    useEffect(() => {
        if(data){
            setteam_name(data.team.team_name);
            setteam_desc(data.team.team_desc);
            setcaptain_id(data.team.captain_id)
    
        }
    }, [data])
    useEffect(()=>{
        if(error){
          NotificationManager.error(error, 'Error', 3000);
        }
      },[error])

    const handleEdit = () =>{
        //console.log({team_name,team_desc});
        const payload = {
            "team_name": team_name,
            "team_desc": team_desc,
            'captain_id':captain_id
          }
          postData(process.env.REACT_APP_NODE_SERVER+"/api/team/update/"+teamId,payload,"PUT");
    }

    useEffect(()=>{
        if(resData && resData.status===200){
            NotificationManager.success(resData.message, 'Success', 3000);
            setIsEdit(false);
        }
        if(postError){
            NotificationManager.error(postError, 'Error', 3000);
        }
    },[resData,postError])

    // code for adding team managers using modal starts
      
    const toggle = (teamId) => {
      if(!modal){
        setTeamId(teamId);
        // setCaptainFirstName('');
        // setCaptainLastName('');
        // setCaptainEmail('');
        // setCaptainContact('');
        // setCaptainPassword('');
      }
      setModal(!modal);
    };

    const handleRegisterCaptain = (e) => {
      e.preventDefault();
      if (captainFirstName === "" || captainEmail === "" || captainContact === "" || captainPassword === "") {
        NotificationManager.error("All the fields are required!", 'Error', 3000);
        return;
      }
      setIsLoader(true)
      const payload = {
          "f_name": captainFirstName,
          "l_name": captainLastName,
          "email": captainEmail,
          "contact": captainContact,
          "password": captainPassword,
          "archive":0
      }
      createTm(process.env.REACT_APP_NODE_SERVER+'/api/captain/register', payload, 'POST');
    };
    useEffect(() => {
      if(tmRes && tmRes.status===200){
        NotificationManager.success("Team Manager Created Successfully!", 'Success', 1200);
        var captain_id = tmRes.userId;
        handleAddTeamManagerInTeam(captain_id)
      }
      if(tmErr){
        NotificationManager.error(tmErr, 'Error', 1200);
      }
    }, [tmRes,tmErr])

    const handleAddTeamManagerInTeam = (captain_id) =>{
      const payload = {
        "captain_id":captain_id
      }
      joinTm(process.env.REACT_APP_NODE_SERVER+'/api/team/addCaptain/'+Number(team_id), payload, 'PUT');
    }
    
    useEffect(() => {
      if(joinTmRes && joinTmRes.status === 200){
        NotificationManager.success("Team Manager Successfully Added To The Team!", 'Success', 1200);
        handleSendEmail();
      }
    
    }, [joinTmRes, joinTmErr])
    

    const handleSendEmail = () =>{
      const payload = {
        "to" : captainEmail,
        "subject" : "Team Manager Registration successfull! - Equestrain Show",
        "text":`Please find your credentials below,Team Name: ${team_name} Email: ${captainEmail} Password: ${captainPassword}`
    }
    sendEmail(process.env.REACT_APP_NODE_SERVER+'/api/team/send-email',payload,"POST")
    }
    useEffect(()=>{
      if(emailRes && emailRes.status===200){
        NotificationManager.success("Email sent Successfully!", 'Success', 3000);
        setTimeout(() => {
          setIsLoader(false);
          setModal(false)
          window.location.reload();
        }, 1000);
      }
      if (emailError) {
        NotificationManager.error(emailError, 'Error', 3000);
        return;
      }
    },[emailRes,emailError])

    // code for adding team managers using modal ends
  return (
    <div className="container pt-8">
        <NotificationContainer />

        {team_id && 
          <Modal isOpen={modal} toggle={toggle} size="md">
            <ModalHeader toggle={toggle}><h3>Add Another Team Manager</h3></ModalHeader>
            <ModalBody>
                { !isLoader && 
                  <div>
                      <form onSubmit={handleRegisterCaptain}>
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="First Name"
                              type="text"
                              value={captainFirstName}
                              onChange={(e) => setCaptainFirstName(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Last Name"
                              type="text"
                              value={captainLastName}
                              onChange={(e) => setCaptainLastName(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Email"
                              type="email"
                              value={captainEmail}
                              onChange={(e) => setCaptainEmail(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Team Manager Contact"
                              type="number"
                              value={captainContact}
                              onChange={(e) => setCaptainContact(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <Input
                                placeholder="Captain Password"
                                type={showPassword ? 'text' : 'password'}
                                value={captainPassword}
                                onChange={(e) => setCaptainPassword(e.target.value)}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText
                                        style={{ cursor: 'pointer' }}
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? '👁️' : '👁️‍🗨️'}
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                        <div className="text-center">
                          {!isPending && <Button className="my-2" color="primary" type="submit">
                            Register Team Manager
                          </Button>}
                          {isPending && <Button className="my-2" color="primary" disabled>
                            <Spinner size="sm" />
                            {' '}Registering...
                          </Button>}
                        </div>
                      </form>
                  </div>
                }
                {
                  isLoader &&
                  <div className="text-center">
                    <Spinner color="primary" style={{ height: '3rem', width: '3rem' }}></Spinner>
                    <p>Do not refresh!</p>
                    <p>Sending email to the team manager...</p>
                  </div>
                }
            </ModalBody>
          </Modal>
        }

        {isLoader &&
        <div className="text-center">
          <Spinner color="primary" style={{ height: '3rem', width: '3rem' }}></Spinner>
          <p>Do not refresh!</p>
          <p>Sending email to the team manager...</p>
        </div>}

        <Container fluid>
        {isPending && <div className="text-center"><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></div>}
        {!isPending && data && <Row className="mt-5">
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
            <Row className="justify-content-center">
                  <Col className="order-lg-2 pb-8" lg="3">
                    <div className="card-profile-image">
                      <a href={process.env.REACT_APP_NODE_SERVER+"/uploads/"+data.team.team_logo} target="_blank" rel="noreferrer" download>
                        <img
                          alt="..."
                          className="rounded-circle"
                          style={{height:"180px", width:"300px",objectFit:'cover'}}
                          src={process.env.REACT_APP_NODE_SERVER+"/uploads/"+data.team.team_logo}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
              <CardBody className="pt-0 pt-md-4">
                <div className="text-center">
                 
                  <hr className="my-4" />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Team Details</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    {!isEdit && <Button
                      color="success"
                      onClick={() => setIsEdit(true)}
                      size="sm"
                    >
                      Edit
                    </Button>}
                    {isEdit && <Button
                      color="success"
                      onClick={handleEdit}
                      size="sm"
                    >
                      Save
                    </Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
              {updatePending && <div className="text-center"><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}> Loading...</Spinner></div>}
                {!updatePending && <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Team information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Team name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={team_name}
                            id="input-first-name"
                            placeholder="Team name"
                            type="text"
                            readOnly={!isEdit}
                            onChange={(e)=>setteam_name(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Team Description
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={team_desc}
                            id="input-last-name"
                            placeholder="Team Description"
                            type="text"
                            readOnly={!isEdit}
                            onChange={(e)=>setteam_desc(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>}
              </CardBody>
            </Card>
            <div className="py-3" onClick={()=>toggle(teamId)}>
              <Button color="primary">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{fontWeight:'700'}}width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                </svg>{' '}
                Add Team Manager
              </Button>
            </div>
          </Col>
        </Row>}
      </Container>
    </div>
  )
}
